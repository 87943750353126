import {inject, injectable} from "inversify";
import {AxiosResponse} from "axios";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import {Bindings} from "data/constants/bindings";
import {GAME_SLUG} from "data/constants";
import {IRoundLineup} from "data/stores/team/team.store";
import {PlayerStatus} from "data/enums";

export interface ITeamPayload {
	tournamentId: number;
}

export interface ITeamRoundPts {
	roundId: number;
	pts: number;
	ptsByPlayerId: Record<number, number>;
}

export interface IAutoSubstitution {
	roundId: number;
	playerMovedFromLineupToBench: number;
	playerMovedFromBenchToLineup: number;
	reasonStatus: PlayerStatus;
	isModalShown: boolean;
}

export interface ITeamResponse {
	id: number;
	fedexPts: number | null;
	totalPts: number | null;
	roundPts: ITeamRoundPts[];
	totalPtsByPlayerId: Record<number, number> | null;
	fedexPtsByPlayerId: Record<number, number> | null;
	teamStructure: IRoundLineup[];
	autoSubstitutions?: IAutoSubstitution[];
}

export interface ISaveTeamPayload {
	tournamentId: number;
	roundId: number;
	captainId: number;
	lineup: number[];
	bench: number[];
}

export type TAutoFillTeamPayload = {
	tournamentId: number;
	captainId?: number;
	lineup?: number[];
	bench?: number[];
};

export interface ITeamProvider {
	get_team: (params: ITeamPayload) => Promise<AxiosResponse<IApiResponse<ITeamResponse>>>;
	save_team: (params: ISaveTeamPayload) => Promise<AxiosResponse<IApiResponse<ITeamResponse>>>;
	auto_fill: (
		params: TAutoFillTeamPayload
	) => Promise<AxiosResponse<IApiResponse<ITeamResponse>>>;
	generate_share_img: (
		params: ITeamPayload
	) => Promise<AxiosResponse<IApiResponse<{image: string}>>>;
	autoSubstitutionModalShownSave: (roundId: number) => Promise<AxiosResponse<IApiResponse>>;
}

@injectable()
export class TeamApiProvider implements ITeamProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	get_team = ({tournamentId, ...params}: ITeamPayload) =>
		this._http.get<IApiResponse<ITeamResponse>>(`${GAME_SLUG}/team/${tournamentId}`, params);
	save_team = ({tournamentId, roundId, ...params}: ISaveTeamPayload) =>
		this._http.post<IApiResponse<ITeamResponse>>(
			`${GAME_SLUG}/team/${tournamentId}/round/${roundId}/save`,
			params
		);
	auto_fill = ({tournamentId, ...params}: TAutoFillTeamPayload) =>
		this._http.post<IApiResponse<ITeamResponse>>(
			`${GAME_SLUG}/team/${tournamentId}/autofill`,
			params
		);
	generate_share_img = ({tournamentId}: ITeamPayload) =>
		this._http.post<IApiResponse<{image: string}>>(
			`${GAME_SLUG}/team/${tournamentId}/generate-image`
		);
	autoSubstitutionModalShownSave = (roundId: number) =>
		this._http.post<IApiResponse>(`${GAME_SLUG}/team/auto-substitution-modal-shown/save`, {
			roundId,
		});
}
