import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {makeAutoObservable, observable} from "mobx";
import {RequestState} from "data/enums";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {ITeamStore} from "data/stores/team/team.store";

export interface IPromptModalController extends ViewController {
	get i18n(): ILocalizationStore;
	get isPromptActive(): boolean;
	get isCurrentLineupFull(): boolean;

	onConfirm: () => void;
}

@injectable()
export class PromptModalController implements IPromptModalController {
	@observable _requestState: RequestState = RequestState.IDLE;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore
	) {
		makeAutoObservable(this);
	}

	get i18n() {
		return this._i18nStore;
	}

	get isPromptActive() {
		return this._teamStore.isPromptActive;
	}

	get isCurrentLineupFull() {
		return this._teamStore.isCurrentLineupFull;
	}

	onConfirm = () => {
		this._teamStore.clearIsPromptActive();
		this._teamStore.clearLineup();
		this._teamStore.setEditTeam(false);
	};

	dispose() {
		return;
	}

	init(param: void) {
		return;
	}
}
