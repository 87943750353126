import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITournament, ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import {eq, isNull} from "lodash";
import React from "react";
import {IRound} from "data/stores/rounds/rounds.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType} from "data/enums";
import {TrackAnalytics} from "data/utils/analytic_service";
import dayjs from "dayjs";
import type {ITeamStore} from "data/stores/team/team.store";

export interface ITournamentsSelectorController extends ViewController {
	readonly i18n: ILocalizationStore;
	get isOpenMenu(): boolean;
	get selectedTournament(): ITournament | undefined;
	get tournamentList(): ITournament[];
	// get availableTournamentsIds(): number[];
	get anchorEl(): null | HTMLElement;
	get playingRound(): IRound | undefined;
	get currentRound(): IRound | undefined;
	get isSelectedTournamentScore(): boolean;
	get selectedRoundIndex(): number;
	get tournamentDate(): string;

	toggleMenu: (event: React.MouseEvent<HTMLElement>) => void;
	toggleTutorial: () => void;
	closeMenu: () => void;
	onSelect: (value: ITournament) => void;
}

@injectable()
export class TournamentsSelectorController implements ITournamentsSelectorController {
	@observable private _isOpenMenu: boolean = false;
	@observable private _anchorEl: null | HTMLElement = null;

	get isOpenMenu() {
		return this._isOpenMenu;
	}

	get selectedTournament() {
		if (!isNull(this._tournamentsStore.selectedTournament)) {
			return this._tournamentsStore.selectedTournament;
		}
	}

	get tournamentDate() {
		if (!this.selectedTournament) return "";

		const startDate = dayjs(this.selectedTournament.startDate);
		const endDate = dayjs(this.selectedTournament.endDate);
		const isOneMonth = eq(
			Number(dayjs(startDate).format("M")),
			Number(dayjs(endDate).format("M"))
		);

		return isOneMonth
			? `${dayjs(startDate).format("MMM D")} - ${dayjs(endDate).format("D")}`
			: `${dayjs(startDate).format("MMM D")} - ${dayjs(endDate).format("MMM D")}`;
	}

	get tournamentList() {
		return this._tournamentsStore.list;
	}

	// get availableTournamentsIds() {
	// 	return this._tournamentsStore.availableTournamentsIds;
	// }

	get anchorEl() {
		return this._anchorEl;
	}

	get playingRound() {
		return this._tournamentsStore.playingRound;
	}

	get currentRound() {
		return this._tournamentsStore.currentRound;
	}

	get isSelectedTournamentScore() {
		return this._tournamentsStore.isSelectedTournamentScore;
	}

	get selectedRoundIndex() {
		return this._tournamentsStore.selectedRoundIndex;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.TournamentsStore) public _tournamentsStore: ITournamentsStore,
		@inject(Bindings.ModalsStore) public _modalsStore: IModalsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore
	) {
		makeAutoObservable(this);
	}

	toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
		this._anchorEl = event.currentTarget;
		this._isOpenMenu = !this._isOpenMenu;
	};

	toggleTutorial = () => {
		this._modalsStore.showModal(ModalType.TUTORIAL, {
			message: "",
		});
	};

	closeMenu = () => {
		this._isOpenMenu = false;
	};

	private setSelectedTournament = (value: ITournament) => {
		const initialValue = this._tournamentsStore.selectedTournament?.name
			.toLowerCase()
			.replaceAll(" ", "_");
		const endValue = value.name.toLowerCase().replaceAll(" ", "_");

		TrackAnalytics.trackOnAction({
			event: "Button Clicked",
			SelectionName: "calendar",
			InitialValue: initialValue,
			EndValue: endValue,
		});

		this._tournamentsStore.setSelectedTournament(value);
		this._tournamentsStore.setSelectedRoundIndex(this._tournamentsStore.currentRoundIndex);
		this.closeMenu();
	};

	onSelect = (value: ITournament) => {
		if (!this._teamStore.isEditTeam) {
			this.setSelectedTournament(value);
			return;
		}

		this._modalsStore.showModal(ModalType.DONT_LOSE_TEAM, {
			callback: () => {
				this.setSelectedTournament(value);
			},
		});
	};

	init(param: void) {
		// this._tournamentsStore.setSelectedTournament(
		// 	this._tournamentsStore.currentTournament ?? null
		// );
	}
}
