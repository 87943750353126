import React from "react";
import {observer} from "mobx-react";
import {Box, Button, Modal, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import CloseIcon from "@mui/icons-material/Close";
import type {IModalTutorialController} from "views/components/modals/modal_tutorial/modal_tutorial.controller";
import {partial, range} from "lodash";
import {Exist} from "views/components/exist/exist.component";
import RightIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import LeftIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import {TUTORIAL_STEPS} from "data/constants";
import {ButtonPrimary, ButtonPrimaryInvert} from "views/components/buttons/buttons.component";
import IconButton from "@mui/material/IconButton";

const ModalContent = styled.div`
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	gap: 12px;
	outline: none;
	max-width: 600px;
	width: 100%;
	background: #fff;
	padding: 20px 20px 16px;
	border-radius: 4px;
	text-align: center;
	min-height: 396px;

	@media screen and (max-width: 640px) {
		max-width: unset;
		left: 24px;
		right: 24px;
		width: auto;
		transform: translateY(-50%);
		min-height: 368px;
	}

	button {
		max-width: 300px;
		//color: #000;
	}

	p {
		color: #000;
		text-align: center;
		font-feature-settings:
			"clig" off,
			"liga" off;
		font-family: var(--fontFamilyBase);
		font-size: 18px;
		font-style: normal;
		font-weight: normal;
		line-height: 123%; /* 44.28px */
		letter-spacing: -0.7px;

		&.title {
			font-size: 36px;
			font-weight: 600;
			text-transform: capitalize;

			@media screen and (max-width: 640px) {
				font-size: 24px;
				line-height: 116%;
				letter-spacing: -0.5px;
			}
		}
	}
`;

const CloseBlock = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	button {
		min-width: unset;
		width: 24px;
		height: 24px;
		border: 1px solid transparent;
		background: #000;
		border-radius: 50%;

		&:hover {
			border: 1px solid #000;

			svg {
				path {
					fill: #000;
				}
			}
		}

		svg {
			width: 16px;
			height: 16px;

			path {
				fill: #fff;
			}
		}
	}
`;
const ModalBody = styled(Box)`
	width: 100%;
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
`;
const TooltipDots = styled("div")`
	min-width: 84px;
	display: flex;
	justify-content: space-between;
	gap: 6px;
`;
const ButtonRow = styled("div")`
	width: 100%;
	padding-top: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 12px;

	button {
		min-width: 174px;
		white-space: nowrap;

		@media screen and (max-width: 640px) {
			width: 100%;
			min-width: 0;
		}
	}
`;

const TooltipDot = styled("span")<{isActive: boolean}>`
	box-sizing: border-box;
	height: 10px;
	width: ${({isActive}) => (isActive ? "20px" : "10px")};
	border-radius: ${({isActive}) => (isActive ? "5px" : "50%")};
	background: ${({isActive}) => (isActive ? "#000" : "#E5E5E5")};
`;
const TooltipControl = styled("div")`
	margin-top: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
`;
const ArrowContainer = styled(IconButton)`
	svg {
		width: 16px;
		height: 16px;
	}
`;
const ImageContainer = styled(Box)`
	width: 100%;
	height: 100px;
	margin: 8px 0;

	img {
		height: 100px;
		width: auto;
	}
`;

export const TutorialModal: React.FC = observer(() => {
	const {
		isOpen,
		i18n,
		isLastStep,
		activeStep,
		currentStep,
		isShowButtons,
		close,
		setNextStep,
		setActiveStep,
	} = useViewController<IModalTutorialController>(Bindings.ModalTutorialController);

	return (
		<Modal open={isOpen} disablePortal>
			<ModalContent>
				<CloseBlock>
					<Button sx={{minWidth: 0}} onClick={close}>
						<CloseIcon />
					</Button>
				</CloseBlock>
				<ModalBody>
					<Exist when={currentStep.image}>
						<ImageContainer>
							<img src={currentStep.image} alt="tutorial step" />
						</ImageContainer>
					</Exist>

					<Typography className="title">{i18n.t(currentStep.title)}</Typography>

					<Typography className="min-h-[60px] md:min-h-[72px]">
						{i18n.t(currentStep.description)}
					</Typography>

					<Exist when={!isShowButtons}>
						<TooltipControl>
							<ArrowContainer onClick={partial(setNextStep, -1)}>
								<LeftIcon />
							</ArrowContainer>
							<TooltipDots>
								{range(TUTORIAL_STEPS.length).map((_, i) => (
									<TooltipDot key={i} isActive={activeStep === i} />
								))}
							</TooltipDots>
							<ArrowContainer onClick={partial(setNextStep, 1)}>
								<RightIcon />
							</ArrowContainer>
						</TooltipControl>
					</Exist>

					<Exist when={isShowButtons}>
						<ButtonRow>
							<Exist when={activeStep === 0}>
								<ButtonPrimaryInvert onClick={partial(setNextStep, 1)}>
									{i18n.t("tutorial.button.view_tutorial", "View Tutorial")}
								</ButtonPrimaryInvert>
								<ButtonPrimary onClick={close}>
									{i18n.t("tutorial.button.start_copy", "Start Playing")}
								</ButtonPrimary>
							</Exist>
							<Exist when={isLastStep}>
								<ButtonPrimaryInvert onClick={partial(setActiveStep, 1)}>
									{i18n.t("tutorial.button.view_tutorial", "Replay Tutorial")}
								</ButtonPrimaryInvert>
								<ButtonPrimary onClick={close}>
									{i18n.t("tutorial.button.start_copy", "Exit Tutorial")}
								</ButtonPrimary>
							</Exist>
						</ButtonRow>
					</Exist>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
});
