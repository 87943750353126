import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IPlayersStore} from "data/stores/players/players.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IPoolPlayersStore} from "data/stores/pool_players/pool_players.store";
import type {IPoolPlayer} from "data/providers/api/tournament.provider";
import type {ITeamStore} from "data/stores/team/team.store";
import {eq, includes} from "lodash";
import type {IPlayerModalStore} from "data/stores/player_modal/player_modal.store";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";

interface IProps {
	playerId: number;
}

export interface IPlayerSlotController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get player(): IPoolPlayer | null | undefined;
	get isCaptain(): boolean;
	get selectedRoundIndex(): number;
	get totalScoredPts(): number | null | undefined;
	get isDisabledCreateTeam(): boolean;

	onOpenPoolModal: () => void;
	openPlayerModal: () => void;
}

@injectable()
export class PlayerSlotController implements IPlayerSlotController {
	@observable private _playerId!: number;

	get player() {
		if (!this._playerId) {
			return null;
		}
		return this._poolPlayersStore.getPoolPlayerById(this._playerId);
	}

	get isCaptain() {
		return eq(this._playerId, this._teamStore.captainId);
	}

	get selectedRoundIndex() {
		return this._tournamentsStore.selectedRoundIndex;
	}

	get isBenchPlayer() {
		return includes(this._teamStore.bench, this._playerId);
	}

	get pageArea() {
		return this.isBenchPlayer ? "bench" : "starters";
	}

	get totalScoredPts() {
		const totalPts = this._teamStore.team.totalPtsByPlayerId?.[this._playerId];
		return totalPts || this.player?.pts;
	}

	get isDisabledCreateTeam() {
		return this._tournamentsStore.isSelectedTournamentDisabledByStatus;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.PlayersStore) public _playerStore: IPlayersStore,
		@inject(Bindings.PoolPlayersStore) public _poolPlayersStore: IPoolPlayersStore,
		@inject(Bindings.TeamStore) public _teamStore: ITeamStore,
		@inject(Bindings.PlayerModalStore) public _playerModalStore: IPlayerModalStore,
		@inject(Bindings.TournamentsStore) public _tournamentsStore: ITournamentsStore
	) {
		makeAutoObservable(this);
	}

	onOpenPoolModal = () => {
		this._poolPlayersStore.toggleHandler();
	};

	openPlayerModal = () => {
		this._playerModalStore.setPlayerId(this._playerId);
		this._playerModalStore.setPageArea(this.pageArea);
		this._playerModalStore.openPlayerModal();
	};

	init({playerId}: IProps) {
		this._playerId = playerId;
	}
}
