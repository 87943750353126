import {makeAutoObservable, observable, reaction, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {GigyaScreenType, RequestState} from "data/enums";
import type {ITeamStore} from "data/stores/team/team.store";
import type {ILeaderboardsStore} from "data/stores/leaderboards/leaderboards.store";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import type {IPoolPlayersStore} from "data/stores/pool_players/pool_players.store";
import type {IUserStore} from "data/stores/user/user.store";
import {SSO} from "data/utils/gigya_sso";
import {useNavigate} from "react-router-dom";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {TrackAnalytics} from "data/utils/analytic_service";

interface IProps {
	navigate: ReturnType<typeof useNavigate>;
}

export interface IGameController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get isLoading(): boolean;
}

@injectable()
export class GameController implements IGameController {
	@observable _requestState: RequestState = RequestState.IDLE;
	@observable _navigate!: ReturnType<typeof useNavigate>;
	private _tournamentReaction!: ReturnType<typeof reaction>;
	private _authReaction!: ReturnType<typeof reaction>;
	private _leagueReaction!: ReturnType<typeof reaction>;
	_sso = new SSO();

	get isLoading() {
		return this._requestState === RequestState.PENDING;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore,
		@inject(Bindings.TournamentsStore) public _tournamentsStore: ITournamentsStore,
		@inject(Bindings.PoolPlayersStore) public _poolPlayersStore: IPoolPlayersStore,
		@inject(Bindings.TeamStore) public _teamStore: ITeamStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.LeaderboardsStore) public _leaderboardStore: ILeaderboardsStore
	) {
		makeAutoObservable(this);
	}

	private onJoinLeague = () => {
		const joinCode = localStorage.getItem("join_code") || null;

		if (joinCode) {
			this._navigate(`/leagues/join/${joinCode}`, {replace: true});
		}
	};

	private onLoadPageTrack = () => {
		TrackAnalytics.trackOnLoad();
	};

	private userAction = (tournamentId: number) => {
		if (this._userStore.isAuthorized) {
			void this._teamStore.fetchTeam({tournamentId});
		}

		// if (!this._tournamentsStore.isSelectedTournamentDisabled) {
		void this._leaderboardStore.fetchPlayersRanking({tournamentId});
		// }
	};

	dispose() {
		this._tournamentReaction?.();
		this._authReaction?.();
		this._leagueReaction?.();
	}

	private scrollToTop() {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}

	init({navigate}: IProps) {
		this.scrollToTop();
		this._navigate = navigate;
		this.onLoadPageTrack();

		const joinCode = localStorage.getItem("join_code") || null;

		this._tournamentsStore.setSelectedTournament(
			this._tournamentsStore.currentTournament ?? null
		);

		this._tournamentsStore.setSelectedRoundIndex(this._tournamentsStore.currentRoundIndex);

		const tournamentId = this._tournamentsStore.selectedTournamentId;

		this._tournamentReaction = reaction(
			() =>
				[this._tournamentsStore.selectedTournamentId, this._userStore.isAuthorized] as [
					number,
					boolean,
				],
			([tournamentId]) => {
				if (tournamentId) {
					this._requestState = RequestState.PENDING;

					void Promise.all([
						this._poolPlayersStore.fetchPoolPlayers({tournamentId}),
						this._tournamentsStore.fetchUsesPlayers({tournamentId}),
						this._tournamentsStore.fetchExpertTeams({tournamentId}),
						this._leaderboardStore.fetchSidebarRanking(),
					])
						.then(() => {
							this.userAction(tournamentId);
						})
						.then(() => (this._requestState = RequestState.SUCCESS))
						.catch(() =>
							runInAction(() => {
								this._requestState = RequestState.ERROR;
							})
						);
				}
			},
			{fireImmediately: true}
		);

		this._authReaction = reaction(
			() => this._userStore.isAuthorized,
			(isAuth) => {
				if (isAuth) {
					this.userAction(tournamentId);
				}
			}
		);

		this._leagueReaction = reaction(
			() => this._userStore.isAuthorized,
			(isAuth) => {
				if (isAuth) {
					void this._leaguesStore
						.fetchMyLeagues({
							limit: 3,
							orderBy: "numTeams",
						})
						.then(() => this.onJoinLeague());
				} else {
					if (joinCode) {
						this._sso.showScreenSet(GigyaScreenType.Login);
					}
				}
			},
			{fireImmediately: true}
		);
	}
}
