import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {AxiosError} from "axios";
import {ViewController} from "data/types/structure";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {
	ILadderFilter,
	ILeaderboardItem,
	type ILeaderboardsStore,
} from "data/stores/leaderboards/leaderboards.store";
import {ITournament, type ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import {ModalType, OrderBy, RequestState, SortOrder} from "data/enums";
import {ISegment, type ISegmentsStore} from "data/stores/segments/segments.store";
import {Bindings} from "data/constants/bindings";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {extractErrorMessage} from "data/utils";
import {IApiResponse} from "data/services/http";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import {chain} from "lodash";
import {TrackAnalytics} from "data/utils/analytic_service";
import {ChangeEvent} from "react";

export interface ILeaderboardController extends ViewController {
	i18n: ILocalizationStore;

	get isLoading(): boolean;
	get isLoadingMore(): boolean;
	get rankingsList(): ILeaderboardItem[];
	get nextPage(): boolean;
	get userItem(): ILeaderboardItem | null;
	get tournaments(): ITournament[];
	get ladderFilter(): ILadderFilter;
	get selectedTournament(): ITournament | undefined;
	get currentSortBy(): string;
	get currentOrder(): SortOrder;
	get segments(): ISegment[];

	loadMoreUsers: () => void;
	onTournamentChange: (tournamentId: number) => void;
	onSegmentChange: (segmentId: number) => void;
	onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
	onSortByStat: (stat: OrderBy) => void;
}

@injectable()
export class LeaderboardController implements ILeaderboardController {
	@observable private _page = 1;
	@observable private _limit = 10;
	@observable private _requestState = RequestState.IDLE;
	@observable private _requestStateLoadMore = RequestState.IDLE;
	@observable _currentSortBy: string = OrderBy.TotalPoints;
	@observable _currentOrder = SortOrder.ASC;

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaderboardsStore) readonly _leaderboardsStore: ILeaderboardsStore,
		@inject(Bindings.ModalsStore) readonly _modalsStore: IModalsStore,
		@inject(Bindings.SegmentsStore) private _segmentsStore: ISegmentsStore,
		@inject(Bindings.TournamentsStore) public _tournamentsStore: ITournamentsStore,
		@inject(Bindings.LeaguesStore) public _leaguesStore: ILeaguesStore
	) {
		makeAutoObservable(this);
	}

	get isLoading(): boolean {
		return this._requestState === RequestState.PENDING;
	}

	get isLoadingMore(): boolean {
		return this._requestStateLoadMore === RequestState.PENDING;
	}

	get rankingsList(): ILeaderboardItem[] {
		return chain(this._leaderboardsStore.leaderboard.rankings)
			.filter(({userId}) => userId !== this.userItem?.userId)
			.value();
	}

	get nextPage(): boolean {
		return this._leaderboardsStore.leaderboard.nextPage;
	}

	get userItem(): ILeaderboardItem | null {
		return this._leaderboardsStore.leaderboard.user;
	}

	get ladderFilter() {
		return this._leaderboardsStore.ladderFilter;
	}

	get tournaments(): ITournament[] {
		if (this.ladderFilter.selectedSegmentId) {
			return this._tournamentsStore.list.filter(
				({seasonSegmentId}) => seasonSegmentId === this.ladderFilter.selectedSegmentId
			);
		}

		return this._tournamentsStore.list;
	}

	get selectedTournament() {
		return this.tournaments.find((it) => it.id === this.ladderFilter.selectedTournamentId);
	}

	get currentSortBy(): string {
		return this._currentSortBy;
	}

	get currentOrder(): SortOrder {
		return this._currentOrder;
	}

	get segments() {
		return this._segmentsStore.list;
	}

	private get requestParams() {
		return {
			page: this._page,
			limit: this._limit,
			orderBy: this._currentSortBy,
			orderDirection: this._currentOrder,
			tournamentId:
				this.ladderFilter.selectedTournamentId === 0
					? undefined
					: this.ladderFilter.selectedTournamentId,
			seasonSegmentId:
				this.ladderFilter.selectedSegmentId === 0
					? undefined
					: this.ladderFilter.selectedSegmentId,
			search: this.ladderFilter.search || undefined,
		};
	}

	private onError = (e: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;

		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(e),
		});
	};

	private onLoadPageTrack = () => {
		TrackAnalytics.trackOnLoad();
	};

	init() {
		this.onLoadPageTrack();
		void this._fetchLeaderboard();
	}

	private _fetchLeaderboard = async () => {
		this._page = 1;

		try {
			this._requestState = RequestState.PENDING;

			await this.requestLeaderboard();

			runInAction(() => {
				this._requestState = RequestState.SUCCESS;
			});
		} catch (e) {
			this.onError(e as AxiosError<IApiResponse>);
		}
	};

	@action loadMoreUsers = async () => {
		++this._page;

		try {
			this._requestStateLoadMore = RequestState.PENDING;

			await this.requestLeaderboardMore();

			runInAction(() => {
				this._requestStateLoadMore = RequestState.SUCCESS;
			});
		} catch (e) {
			this.onError(e as AxiosError<IApiResponse>);
		}
	};

	private async requestLeaderboard() {
		await this._leaderboardsStore.fetchLeaderboard(this.requestParams);
	}

	private async requestLeaderboardMore() {
		await this._leaderboardsStore.fetchLeaderboardMore(this.requestParams);
	}

	private getTournamentAnalyticsValue = (tournamentId: number) => {
		const tournament = this._tournamentsStore.list.find(({id}) => id === tournamentId);
		if (!tournament) return "overall";

		return tournament.name.toLowerCase().replaceAll(" ", "_");
	};

	@action onTournamentChange = (tournamentId: number) => {
		TrackAnalytics.trackOnAction({
			event: "Filter Applied",
			SelectionName: "tournament",
			InitialValue: this.getTournamentAnalyticsValue(this.ladderFilter.selectedTournamentId),
			EndValue: this.getTournamentAnalyticsValue(tournamentId),
			PageArea: "primary_controls",
		});

		this._leaderboardsStore.setSelectedTournamentId(tournamentId);

		const futureTournament = this._tournamentsStore.scheduleTournaments.find(
			({id}) => id === tournamentId
		);

		this._currentOrder = SortOrder.ASC;
		this._currentSortBy = futureTournament ? OrderBy.TotalPoints : OrderBy.TournamentPoints;

		void this._fetchLeaderboard();
	};

	private getSegmentAnalyticsValue = (segmentId: number) => {
		const segment = this._segmentsStore.list.find(({id}) => id === segmentId);
		if (!segment) return "overall";

		const segmentName = segment.name.toLowerCase().replaceAll(" ", "_");
		return `segment_${segmentName}`;
	};

	@action onSegmentChange = (segmentId: number) => {
		TrackAnalytics.trackOnAction({
			event: "Filter Applied",
			SelectionName: "segment",
			InitialValue: this.getSegmentAnalyticsValue(this.ladderFilter.selectedSegmentId),
			EndValue: this.getSegmentAnalyticsValue(segmentId),
			PageArea: "primary_controls",
		});

		this._leaderboardsStore.setSelectedSegmentId(segmentId);
		this._leaderboardsStore.setSelectedTournamentId(0);

		void this._fetchLeaderboard();
	};

	@action onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		const {value} = e.currentTarget;

		this._leaderboardsStore.setSearchValue(value);

		void this._fetchLeaderboard();
	};

	@action onSortByStat = (stat: OrderBy) => {
		this._currentSortBy = stat;
		this._currentOrder = this._currentOrder === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
		void this._fetchLeaderboard();

		// this._leaderboardsStore.setOrderFilter({
		// 	orderBy: this._currentSortBy,
		// 	orderDirection: this._currentOrder
		// });
	};

	dispose() {
		this._leaderboardsStore.clearStore();
	}
}
