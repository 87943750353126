import React from "react";
import {observer} from "mobx-react";
import {Modal, Stack, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {partial} from "lodash";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ModalContent} from "views/components/modals/modalStyles";
import {Exist} from "views/components/exist/exist.component";
import {Preloader} from "views/components/preloader";
import {IModalCompareController} from "./modal_compare.controller";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {ReactComponent as CloseIcon} from "assets/img/icons/close.svg";
import {Tournament} from "./tournament/tournament.component";

const TabsWrapper = styled(Box)`
	border-bottom: 1px solid #949494;

	.MuiTabs-root {
		min-height: 44px;

		@media (min-width: 768px) {
			min-height: 50px;
		}
	}

	.MuiTabs-flexContainer {
		button {
			color: #000000;
			text-align: center;
			font-size: 12px;
			font-weight: 600;

			@media (min-width: 768px) {
				font-size: 14px;
			}
		}

		.Mui-disabled {
			color: #757575;
		}
	}

	.MuiTabs-indicator {
		background-color: #000;
	}
`;

export const ModalCompare: React.FC = observer(() => {
	const {
		isOpen,
		i18n,
		close,
		segments,
		selectedSegmentId,
		changeSegmentId,
		tournaments,
		isLoading,
		teamsName,
		getIsOpen,
		toggleHandler,
		ref,
	} = useViewController<IModalCompareController>(Bindings.ModalCompareController);

	return (
		<Modal open={isOpen} onClose={close} disablePortal>
			<ModalContent className="rounded-lg p-0 gap-0 max-w-[824px]">
				<div className="p-4 sticky top-0 bg-white flex justify-between items-center w-full z-[1]">
					<h4 className="font-semibold text-[18px] md:text-[36px] leading-[120%]">
						{i18n.t("modal.compare.title", "Compare")}
					</h4>

					<IconButton className="p-0" onClick={close}>
						<CloseIcon />
					</IconButton>
				</div>

				<div className="flex flex-col w-full mt-0.5 p-4 pt-0 max-[380px]:px-2">
					<TabsWrapper>
						<Tabs
							variant="scrollable"
							value={selectedSegmentId}
							onChange={changeSegmentId}>
							{segments.map((segment) => (
								<Tab
									disabled={segment.isDisabled}
									className="whitespace-nowrap flex-1 min-h-[44px] md:min-h-[50px]"
									label={i18n.t("modal.compare.segment", "Segment {{X}}", {
										X: segment.name,
									})}
									value={segment.id}
									key={segment.id}
								/>
							))}
						</Tabs>
					</TabsWrapper>

					<div className="flex justify-between py-3.5 px-2 font-semibold border-b border-solid border-black leading-[135%] text-xs md:text-sm">
						<div>{teamsName.teamAName}</div>
						<div>{i18n.t("modal.compare.tournament", "Tournament")}</div>
						<div>{teamsName.teamBName}</div>
					</div>

					<div ref={ref}>
						<Exist when={isLoading}>
							<Stack p={2} justifyContent={"center"} alignItems={"center"}>
								<Preloader />
							</Stack>
						</Exist>

						<Exist when={!isLoading}>
							<Exist when={tournaments.length}>
								{tournaments.map((tournament) => (
									<Tournament
										tournament={tournament}
										isOpen={getIsOpen(tournament.id)}
										key={tournament.id}
										toggleHandler={partial(toggleHandler, tournament.id)}
									/>
								))}
							</Exist>

							<Exist when={!tournaments.length}>
								<Box p={2}>
									<Typography textAlign="center">
										{i18n.t(
											"modal.compare.empty_tournaments_list",
											"There are no tournaments available"
										)}
									</Typography>
								</Box>
							</Exist>
						</Exist>
					</div>
				</div>
			</ModalContent>
		</Modal>
	);
});
