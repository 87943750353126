import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {AxiosError} from "axios";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalPayload, IModalsStore} from "data/stores/modals/modals.store";
import type {ITeamStore} from "data/stores/team/team.store";
import {ModalType, PlayerScoreType} from "data/enums";
import {IAutoSubstitution} from "data/providers/api/team.api.provider";
import type {IPoolPlayersStore} from "data/stores/pool_players/pool_players.store";
import {IPoolPlayer} from "data/providers/api/tournament.provider";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import {IPlayerScorePts} from "views/components/player_slot_row/player_slot_row.controller";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";

interface ISubstitution
	extends Omit<
		IAutoSubstitution,
		"playerMovedFromBenchToLineup" | "playerMovedFromLineupToBench"
	> {
	playerMovedFromBenchToLineup: IPoolPlayer & {playerScoredRoundsPts: IPlayerScorePts[]};
	playerMovedFromLineupToBench: IPoolPlayer & {playerScoredRoundsPts: IPlayerScorePts[]};
}

export interface IModalAutoSwapController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get modalContent(): IModalPayload | null;
	get swaps(): ISubstitution[];
	get selectedRoundIndex(): number;

	close: () => void;
}

@injectable()
export class ModalAutoSwapController implements IModalAutoSwapController {
	get isOpen() {
		return this._modalsStore.modal === ModalType.AUTO_SWAP;
	}

	get modalContent() {
		return this._modalsStore.modalContent;
	}

	private getPlayerData = (playerId: number) => {
		const player = this._poolPlayersStore.getPoolPlayerById(playerId);
		const tournamentRounds = this._tournamentsStore.selectedTournamentRounds;
		const scorePoolRounds = player?.roundStats.map((it) => it.points);

		return {
			...player,
			playerScoredRoundsPts: tournamentRounds.map((round, index) => {
				const scoreRound = this._teamStore.team?.roundPts.find(
					(it) => it?.roundId === round?.id
				);
				const isPlayerScore = Boolean(scoreRound?.ptsByPlayerId[playerId]);
				const currentRound = player?.roundStats[index];
				const isRoundPlaying = Boolean(currentRound?.thru && currentRound.thru !== "F");

				if (isPlayerScore && isRoundPlaying) {
					return {
						roundPoints: scoreRound?.ptsByPlayerId[playerId],
						score: PlayerScoreType.Score,
					};
				}

				if (isPlayerScore) {
					return {
						roundPoints: scoreRound?.ptsByPlayerId[playerId],
						score: PlayerScoreType.Default,
					};
				}

				return {
					roundPoints: scorePoolRounds
						? scorePoolRounds[index]
						: scoreRound?.ptsByPlayerId[playerId],
					score: PlayerScoreType.UnScore,
				};
			}),
		};
	};

	get swaps() {
		return (this._teamStore.team?.autoSubstitutions
			?.filter(({roundId}) => roundId === this.modalContent?.lastAutoSubstitutionRound)
			.map((substitution) => ({
				...substitution,
				playerMovedFromBenchToLineup: this.getPlayerData(
					substitution.playerMovedFromBenchToLineup
				),
				playerMovedFromLineupToBench: this.getPlayerData(
					substitution.playerMovedFromLineupToBench
				),
			}))
			.filter(
				({playerMovedFromLineupToBench, playerMovedFromBenchToLineup}) =>
					playerMovedFromLineupToBench && playerMovedFromBenchToLineup
			) || []) as ISubstitution[];
	}

	get selectedRoundIndex() {
		return this._tournamentsStore.selectedRoundIndex;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.TeamStore) private readonly _teamStore: ITeamStore,
		@inject(Bindings.PoolPlayersStore) private _poolPlayersStore: IPoolPlayersStore,
		@inject(Bindings.TournamentsStore) private _tournamentsStore: ITournamentsStore
	) {
		makeAutoObservable(this);
	}

	close = () => {
		const roundId = this.modalContent?.lastAutoSubstitutionRound;

		this._modalsStore.hideModal();

		if (roundId) {
			this._teamStore
				.autoSubstitutionModalShownSave(roundId)
				.catch((error: AxiosError<IApiResponse>) => {
					this._modalsStore.showModal(ModalType.ERROR, {
						message: extractErrorMessage(error),
					});
				});
		}
	};
}
