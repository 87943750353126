import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITeamStore} from "data/stores/team/team.store";
import {ITeamResponse} from "data/providers/api/team.api.provider";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import {IRound} from "data/stores/rounds/rounds.store";

interface IScoredRound extends IRound {
	pts?: number;
}

export interface ITeamScoredBarController extends ViewController {
	readonly i18n: ILocalizationStore;

	get teamScoredPoints(): Pick<ITeamResponse, "fedexPts" | "totalPts" | "roundPts">;
	get playingRound(): IRound | undefined;
	get selectedRoundIndex(): number;
	get isEditTeam(): boolean;
	get selectedTournamentRounds(): IScoredRound[];

	onSelectRound: (index: number) => void;
}

@injectable()
export class TeamScoredBarController implements ITeamScoredBarController {
	get teamScoredPoints() {
		return this._teamStore.teamScoredPoints;
	}

	get playingRound() {
		return this._tournamentsStore.playingRound;
	}

	get selectedTournamentRounds() {
		return this._tournamentsStore.selectedTournamentRounds.map((round) => {
			return {
				...round,
				pts: this.teamScoredPoints.roundPts.find(({roundId}) => roundId === round.id)?.pts,
			};
		});
	}

	get selectedRoundIndex() {
		return this._tournamentsStore.selectedRoundIndex;
	}

	get isEditTeam() {
		return this._teamStore.isEditTeam;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.TeamStore) public _teamStore: ITeamStore,
		@inject(Bindings.TournamentsStore) public _tournamentsStore: ITournamentsStore
	) {
		makeAutoObservable(this);
	}

	onSelectRound = (index: number) => {
		if (!this.isEditTeam) {
			this._tournamentsStore.setSelectedRoundIndex(index);
		}
	};
}
