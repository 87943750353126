import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import {ModalType} from "data/enums";
import type {IModalsStore, IModalPayload} from "data/stores/modals/modals.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ITeamStore} from "data/stores/team/team.store";

export interface IModalCaptainController extends ViewController {
	i18n: ILocalizationStore;

	get isOpen(): boolean;
	get modalContent(): IModalPayload | null;

	close: () => void;
	selectCaptain: () => void;
}

@injectable()
export class ModalCaptainController implements IModalCaptainController {
	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private readonly _modalsStore: IModalsStore,
		@inject(Bindings.TeamStore) private readonly _teamStore: ITeamStore
	) {
		makeAutoObservable(this);
	}

	get isOpen(): boolean {
		return this._modalsStore.modal === ModalType.SET_CAPTAIN;
	}

	get modalContent() {
		return this._modalsStore.modalContent;
	}

	close = () => {
		this._modalsStore.hideModal();
	};

	selectCaptain = () => {
		this._teamStore.setEditTeam(true);
	};
}
