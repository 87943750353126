import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {compact, concat, eq, fill, get, includes, intersection, size} from "lodash";
import type {
	ISaveTeamPayload,
	ITeamPayload,
	ITeamProvider,
	ITeamResponse,
} from "data/providers/api/team.api.provider";
import {Bindings} from "data/constants/bindings";
import {RequestState, SaveButtonState} from "data/enums";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import {TrackAnalytics} from "data/utils/analytic_service";
import type {IPlayersStore} from "data/stores/players/players.store";

export interface ITrade {
	out: number;
	in: number;
}

const DEFAULT_TRADE: ITrade = {
	out: 0,
	in: 0,
};

export interface IRoundLineup {
	captainId: number;
	lineup: number[];
	bench: number[];
	roundId: number;
}

const DEFAULT_LINEUP_ARRAY: number[] = fill(Array(4), 0);
const DEFAULT_BENCH_ARRAY: number[] = fill(Array(2), 0);

const DEFAULT_ROUND_LINEUP: IRoundLineup = {
	captainId: 0,
	lineup: DEFAULT_LINEUP_ARRAY,
	bench: DEFAULT_BENCH_ARRAY,
	roundId: 0,
};

const DEFAULT_TEAM: ITeamResponse = {
	id: 0,
	fedexPts: null,
	totalPts: null,
	roundPts: [],
	totalPtsByPlayerId: null,
	fedexPtsByPlayerId: null,
	teamStructure: fill(Array(4), DEFAULT_ROUND_LINEUP),
};

export interface ITeamStore {
	get team(): ITeamResponse;

	get currentRoundTeam(): IRoundLineup | undefined;

	get shareTeamImage(): string;

	get isTeamLoading(): boolean;

	get saveTeamPayload(): Omit<ISaveTeamPayload, "tournamentId" | "roundId">;

	get trade(): ITrade;

	get isTradeActive(): boolean;

	get lineup(): number[];

	get bench(): number[];

	get fullTeam(): number[];

	get captainId(): number;

	get isCurrentLineupFull(): boolean;

	get isCurrentLineupHasPlayer(): boolean;

	get lineupSize(): number;

	get saveButtonState(): SaveButtonState;

	get isTeamChanged(): boolean;

	get isPromptActive(): boolean;

	get isEditTeam(): boolean;

	get hasTeam(): boolean;

	get hasPrevTeam(): boolean;

	get teamScoredPoints(): Pick<ITeamResponse, "fedexPts" | "totalPts" | "roundPts">;

	addPlayer(playerId: number): void;

	removePlayer(playerId: number): void;

	clearLineup(): void;

	setTradeOut(playerId: number): void;

	setTradeIn(playerId: number): void;

	swapPlayers(playerId: number): void;

	selectCaptain(playerId: number): void;

	setIsPromptActive: () => void;

	clearIsPromptActive: () => void;

	setEditTeam(value: boolean): void;

	setSaveState(value: SaveButtonState): void;

	fetchTeam(payload: ITeamPayload): Promise<void>;

	saveTeam(payload: ISaveTeamPayload): Promise<void>;

	generateShareImg(payload: ITeamPayload): Promise<void>;

	autoFill(): Promise<void>;

	autoSubstitutionModalShownSave(roundId: number): Promise<void>;

	setIsTeamChanged(payload: boolean): void;
}

@injectable()
export class TeamStore implements ITeamStore {
	@observable private _team: ITeamResponse = DEFAULT_TEAM;
	@observable private _trade: ITrade = DEFAULT_TRADE;
	@observable private _editTeam: boolean = false;
	@observable private _saveButtonState: SaveButtonState = SaveButtonState.SAVE;
	@observable private _hasTeam: boolean = false;
	@observable private _isTeamChanged: boolean = false;
	@observable private _isPromptActive: boolean = false;
	@observable private _shareTeamImage: string = "";
	@observable _requestState: RequestState = RequestState.IDLE;

	get team() {
		return this._team;
	}

	get currentRoundTeam() {
		return this._team.teamStructure[this._tournamentsStore.selectedRoundIndex];
	}

	get shareTeamImage() {
		return this._shareTeamImage;
	}

	get isTeamLoading() {
		return this._requestState === RequestState.PENDING;
	}

	get saveTeamPayload() {
		if (!this.currentRoundTeam) {
			return {
				captainId: 0,
				lineup: DEFAULT_LINEUP_ARRAY,
				bench: DEFAULT_BENCH_ARRAY,
			};
		}
		return {
			captainId: this.currentRoundTeam.captainId,
			lineup: this.currentRoundTeam.lineup,
			bench: this.currentRoundTeam.bench,
		};
	}

	get trade() {
		return this._trade;
	}

	get isTradeActive() {
		return Boolean(this._trade.out);
	}

	get lineup() {
		if (!this.currentRoundTeam) return DEFAULT_LINEUP_ARRAY;

		return this.currentRoundTeam.lineup;
	}

	get bench() {
		if (!this.currentRoundTeam) return DEFAULT_BENCH_ARRAY;

		return this.currentRoundTeam.bench;
	}

	get fullTeam() {
		return concat(this.lineup, this.bench);
	}

	get captainId() {
		if (!this.currentRoundTeam) return 0;

		return this.currentRoundTeam.captainId;
	}

	get isCurrentLineupFull() {
		return !includes(concat(this.lineup, this.bench), 0);
	}

	get isCurrentLineupHasPlayer() {
		return size(compact(concat(this.lineup, this.bench))) > 0;
	}

	get lineupSize() {
		return size(compact(concat(this.lineup, this.bench)));
	}

	get saveButtonState() {
		return this._saveButtonState;
	}

	get isTeamChanged() {
		return this._isTeamChanged;
	}

	get isPromptActive() {
		return this._isPromptActive;
	}

	get isEditTeam() {
		return this._editTeam;
	}

	get teamScoredPoints() {
		return {
			fedexPts: this.team.fedexPts,
			totalPts: this.team.totalPts,
			roundPts: this.team.roundPts,
		};
	}

	get hasTeam() {
		return this._hasTeam;
	}

	get hasPrevTeam() {
		if (this._tournamentsStore.selectedRoundIndex === 0) return false;

		return Boolean(
			compact(this._team.teamStructure[this._tournamentsStore.selectedRoundIndex - 1].lineup)
				.length
		);
	}

	constructor(
		@inject(Bindings.TeamProvider) private _teamProvider: ITeamProvider,
		@inject(Bindings.TournamentsStore) private _tournamentsStore: ITournamentsStore,
		@inject(Bindings.PlayersStore) private _playersStore: IPlayersStore
	) {
		makeAutoObservable(this);
	}

	@action setEditTeam(value: boolean) {
		this._editTeam = value;
	}

	@action
	setSaveState(value: SaveButtonState) {
		this._saveButtonState = value;
	}

	@action
	private setHasTeam(value: boolean) {
		this._hasTeam = value;
	}

	@action
	setIsTeamChanged(value: boolean) {
		this._isTeamChanged = value;
		if (value) this.setSaveState(this._hasTeam ? SaveButtonState.UPDATE : SaveButtonState.SAVE);
	}

	@action setIsPromptActive() {
		this._isPromptActive = true;
	}

	@action clearIsPromptActive() {
		this._isPromptActive = false;
	}

	@action addPlayer(playerId: number) {
		this.setIsPromptActive();

		if (this.isCurrentLineupFull) {
			return;
		}

		const index = this.fullTeam.findIndex((it) => it === 0);

		if (index > 3) {
			this._team.teamStructure[this._tournamentsStore.selectedRoundIndex].bench[index - 4] =
				playerId;
		} else {
			this._team.teamStructure[this._tournamentsStore.selectedRoundIndex].lineup[index] =
				playerId;
		}

		this.setIsTeamChanged(true);

		if (this.isCurrentLineupFull && !this.captainId) {
			this.setEditTeam(true);
		}

		const player = this._playersStore.getPlayerById(playerId);

		TrackAnalytics.trackOnAction({
			event: "Added Player",
			PlayerId: [get(player, "feedId", "")],
			RosterId: [this._team.id],
		});
	}

	@action removePlayer(playerId: number) {
		this.setIsPromptActive();

		const index = this.fullTeam.findIndex((it) => it === playerId);

		if (this.captainId === playerId) {
			this._team.teamStructure[this._tournamentsStore.selectedRoundIndex].captainId = 0;
		}

		if (index > 3) {
			this._team.teamStructure[this._tournamentsStore.selectedRoundIndex].bench[index - 4] =
				0;
		} else {
			this._team.teamStructure[this._tournamentsStore.selectedRoundIndex].lineup[index] = 0;
		}

		this.setIsTeamChanged(true);

		const player = this._playersStore.getPlayerById(playerId);

		TrackAnalytics.trackOnAction({
			event: "Deleted Player",
			PlayerId: [get(player, "feedId", "")],
			RosterId: [this._team.id],
		});
	}

	@action selectCaptain(playerId: number) {
		this._team.teamStructure[this._tournamentsStore.selectedRoundIndex].captainId = playerId;

		this.setIsTeamChanged(true);
		this.setIsPromptActive();

		const player = this._playersStore.getPlayerById(playerId);

		TrackAnalytics.trackOnAction({
			event: "Captain Player",
			PlayerId: [get(player, "feedId", "")],
			RosterId: [this._team.id],
		});
	}

	@action clearLineup() {
		this.clearIsPromptActive();
		this._team = DEFAULT_TEAM;
	}

	@action setTradeOut(playerId: number) {
		if (playerId === this._trade.out) {
			this._trade.out = 0;
		} else {
			this._trade.out = playerId;
		}
	}

	@action setTradeIn(playerId: number) {
		this._trade.in = playerId;
	}

	@action
	private resetCaptain() {
		if (eq(this._trade.out, this._trade.in)) {
			this.setIsTeamChanged(false);
			this.clearIsPromptActive();
			return null;
		}

		if (includes([this._trade.out, this._trade.in], this.captainId)) {
			this._team.teamStructure[this._tournamentsStore.selectedRoundIndex].captainId = 0;
			this.setIsTeamChanged(true);
			this.setIsPromptActive();
		}
	}

	@action
	private onTradeOut(playerId: number) {
		if (this._trade.out && playerId === this._trade.out) {
			this._trade.out = 0;
		}

		if (!this._trade.out) {
			this.setTradeOut(playerId);
		}
	}

	@action
	private onTradeIn(playerId: number) {
		if (this._trade.out) {
			this.setTradeIn(playerId);
		}
	}

	@action
	private onSwapStarterPlayer() {
		const isBothStartedPlayers =
			intersection(
				this._team.teamStructure[this._tournamentsStore.selectedRoundIndex].lineup,
				[this._trade.out, this._trade.in]
			).length === 2;

		if (isBothStartedPlayers) {
			const indexOut = this.lineup.findIndex((it) => it === this._trade.out);
			const indexIn = this.lineup.findIndex((it) => it === this._trade.in);

			this._team.teamStructure[this._tournamentsStore.selectedRoundIndex].lineup[indexOut] =
				this._trade.in;
			this._team.teamStructure[this._tournamentsStore.selectedRoundIndex].lineup[indexIn] =
				this._trade.out;

			this.setIsTeamChanged(true);
			this.setIsPromptActive();
		} else {
			const indexOut = this.lineup.findIndex((it) => it === this._trade.out);
			const indexIn = this.bench.findIndex((it) => it === this._trade.in);

			this._team.teamStructure[this._tournamentsStore.selectedRoundIndex].lineup[indexOut] =
				this._trade.in;
			this._team.teamStructure[this._tournamentsStore.selectedRoundIndex].bench[indexIn] =
				this._trade.out;

			this.setIsTeamChanged(true);
			this.setIsPromptActive();

			if (!this._tournamentsStore.isSelectedTournamentDisabledForPicks) {
				this.resetCaptain();
			}
		}
	}

	@action
	private onSwapBenchPlayer() {
		const isBothBenchPlayers =
			intersection(
				this._team.teamStructure[this._tournamentsStore.selectedRoundIndex].bench,
				[this._trade.out, this._trade.in]
			).length === 2;

		if (isBothBenchPlayers) {
			const indexOut = this.bench.findIndex((it) => it === this._trade.out);
			const indexIn = this.bench.findIndex((it) => it === this._trade.in);

			this._team.teamStructure[this._tournamentsStore.selectedRoundIndex].bench[indexOut] =
				this._trade.in;
			this._team.teamStructure[this._tournamentsStore.selectedRoundIndex].bench[indexIn] =
				this._trade.out;

			this.setIsTeamChanged(true);
			this.setIsPromptActive();
		} else {
			const indexOut = this.bench.findIndex((it) => it === this._trade.out);
			const indexIn = this.lineup.findIndex((it) => it === this._trade.in);

			this._team.teamStructure[this._tournamentsStore.selectedRoundIndex].lineup[indexIn] =
				this._trade.out;
			this._team.teamStructure[this._tournamentsStore.selectedRoundIndex].bench[indexOut] =
				this._trade.in;

			this.setIsTeamChanged(true);
			this.setIsPromptActive();

			if (!this._tournamentsStore.isSelectedTournamentDisabledForPicks) {
				this.resetCaptain();
			}
		}
	}

	@action swapPlayers(playerId: number) {
		this.onTradeIn(playerId);

		this.onTradeOut(playerId);

		if (this._trade.out && this._trade.in) {
			const isStarterTradeOut = includes(
				this._team.teamStructure[this._tournamentsStore.selectedRoundIndex].lineup,
				this._trade.out
			);

			if (isStarterTradeOut) {
				this.onSwapStarterPlayer();
			} else {
				this.onSwapBenchPlayer();
			}

			const tradeOut = this._playersStore.getPlayerById(this._trade.out);
			const tradeIn = this._playersStore.getPlayerById(this._trade.in);

			TrackAnalytics.trackOnAction({
				event: "Swapped Player",
				InitialValue: tradeOut?.feedId,
				EndValue: tradeIn?.feedId,
				RosterId: [this._team.id],
			});

			this._trade = DEFAULT_TRADE;
		}

		// this.setIsTeamChanged(true);
		// this.setIsPromptActive();
	}

	@action private hideActionRow = () => {
		this.setEditTeam(!this._tournamentsStore.isSelectedTournamentDisabledForPicks);
	};

	@action private checkEditMode = () => {
		const query = new URL(window.location.href);
		const params = new URLSearchParams(query.searchParams);
		const isEditMode = Boolean(params.get("editMode"));

		console.log("isEditMode---", isEditMode);

		setTimeout(() => {
			this.setEditTeam(isEditMode);
		}, 500);
	};

	@action
	async fetchTeam(payload: ITeamPayload) {
		this._requestState = RequestState.PENDING;
		try {
			const {data} = await this._teamProvider.get_team(payload);

			runInAction(() => {
				this._team = data.success;
				this.setHasTeam(true);
				this.setSaveState(SaveButtonState.UPDATE);

				if (this._tournamentsStore.isSelectedTournamentDisabledByStatus) {
					this.hideActionRow();
					// this.setEditTeam(false);
				}

				this.checkEditMode();

				this._requestState = RequestState.SUCCESS;
			});
		} catch (_err) {
			runInAction(() => {
				this._team = DEFAULT_TEAM;
				this.setHasTeam(false);
				this.setSaveState(SaveButtonState.SAVE);
				this.setEditTeam(false);
				this._requestState = RequestState.ERROR;
				// this.hideActionRow();
			});
		}
	}

	@action
	async saveTeam(payload: ISaveTeamPayload) {
		this.setSaveState(this._hasTeam ? SaveButtonState.UPDATING : SaveButtonState.SAVING);
		const {data} = await this._teamProvider.save_team(payload);

		runInAction(() => {
			this._team = data.success;
			this.setSaveState(this._hasTeam ? SaveButtonState.UPDATED : SaveButtonState.SAVED);
			this.setIsTeamChanged(false);
			this.clearIsPromptActive();
			this.setEditTeam(false);
			this.setHasTeam(true);

			const playerIds = this.fullTeam.map(
				(it) => this._playersStore.getPlayerById(it)?.feedId
			);
			const captain = this._playersStore.getPlayerById(this.captainId);

			TrackAnalytics.trackOnAction({
				event: "Submitted Roster",
				RosterId: [this._team.id],
				PlayerId: compact(playerIds),
				CaptainPlayerId: [get(captain, "feedId", "")],
			});
		});
	}

	private getAutoFillPayload = () => {
		if (this.captainId) {
			return {
				tournamentId: this._tournamentsStore.selectedTournamentId,
				lineup: compact(this.lineup),
				bench: compact(this.bench),
				captainId: this.captainId,
			};
		}

		return {
			tournamentId: this._tournamentsStore.selectedTournamentId,
			lineup: compact(this.lineup),
			bench: compact(this.bench),
		};
	};

	@action
	async autoFill() {
		// this.setSaveState(this._hasTeam ? SaveButtonState.UPDATING : SaveButtonState.SAVING);
		const {data} = await this._teamProvider.auto_fill(this.getAutoFillPayload());

		runInAction(() => {
			this._team = {
				...DEFAULT_TEAM,
				...data.success,
			};
			// this.setSaveState(this._hasTeam ? SaveButtonState.UPDATED : SaveButtonState.SAVED);
			this.setIsTeamChanged(true);
			this.setIsPromptActive();
			this.setEditTeam(true);
		});
	}

	@action async generateShareImg(payload: ITeamPayload) {
		try {
			const {data} = await this._teamProvider.generate_share_img(payload);

			runInAction(() => {
				this._shareTeamImage = data.success.image;
			});
		} catch (err) {
			console.log(err);
		}
	}

	@action async autoSubstitutionModalShownSave(roundId: number) {
		await this._teamProvider.autoSubstitutionModalShownSave(roundId);
	}
}
