import {makeAutoObservable, observable, action, runInAction} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeaguesStore, ILeagueUsers, ILeague} from "data/stores/leagues/leagues.store";
import type {IRound} from "data/stores/rounds/rounds.store";
import type {IUserStore} from "data/stores/user/user.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, LeagueStatus, RequestState} from "data/enums";
import {isEqual} from "lodash";
import {extractErrorMessage} from "data/utils";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {TrackAnalytics} from "data/utils/analytic_service";

interface IParams {
	leagueId: number;
}

export interface ILeagueManageController extends ViewController<IParams> {
	i18n: ILocalizationStore;

	get userID(): number;
	get league(): ILeague | null;
	get leagueUsers(): ILeagueUsers | null;
	get isLeagueStarted(): boolean;
	get isLoading(): boolean;

	loadMoreUsers: () => void;
	removeUser: (userId: number) => void;
}

@injectable()
export class LeagueManageController implements ILeagueManageController {
	@observable _requestState = RequestState.IDLE;

	@observable private _leagueId?: number;

	private get leagueID() {
		return this._leagueId!;
	}

	get userID() {
		return this._userStore.user!.id;
	}

	get isLoading(): boolean {
		return this._requestState === RequestState.PENDING;
	}

	get leagueUsers() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueUsersByLeagueId(this._leagueId);
	}

	get league() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	get isLeagueStarted() {
		return !isEqual(this.league?.status, LeagueStatus.SCHEDULED);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRound,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@action loadMoreUsers = () => {
		this._requestState = RequestState.PENDING;
		this._leaguesStore
			.fetchMoreLeagueUsers({leagueId: this.leagueID})
			.then(() => {
				runInAction(() => {
					this._requestState = RequestState.SUCCESS;
				});
			})
			.catch((e: AxiosError<IApiResponse>) => {
				this._requestState = RequestState.SUCCESS;
				this._modalsStore.showModal(ModalType.ERROR, {
					message: extractErrorMessage(e),
				});
			});
	};

	@action removeUser = (userId: number) => {
		// console.log(userId);
		const userName =
			this.leagueUsers?.users.find((user) => user.userId === userId)?.displayName ||
			"this user";

		this._modalsStore.showModal(ModalType.CONFIRM, {
			confirmButtonCopy: this.i18n.t("league.leave.confirm", "Yes, Remove"),
			showCancel: true,
			message: this.i18n.t(
				"league_setting.manage_user.confirm_remove",
				`You are removing ${userName}. This action cannot be reversed.`,
				{
					X: userName,
				}
			),
			callback: () => {
				this._modalsStore.hideModal();

				void this._leaguesStore.removeUserFromLeague({userId, leagueId: this.leagueID});
			},
		});
	};

	private onLoadPageTrack = () => {
		TrackAnalytics.trackOnLoad({
			LeagueName: this.league?.name,
		});
	};

	@action init({leagueId}: IParams) {
		this._leagueId = leagueId;

		this._requestState = RequestState.PENDING;
		this._leaguesStore
			.fetchLeagueUsers({leagueId})
			.then(() => {
				runInAction(() => {
					this._requestState = RequestState.SUCCESS;
				});
			})
			.catch((e: AxiosError<IApiResponse>) => {
				this._requestState = RequestState.SUCCESS;
				this._modalsStore.showModal(ModalType.ERROR, {
					message: extractErrorMessage(e),
				});
			});
	}
}
