import {makeAutoObservable} from "mobx";
import {inject, injectable} from "inversify";
import {ViewController} from "data/types/structure";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {ModalType} from "data/enums";
import type {IModalsStore} from "data/stores/modals/modals.store";
import type {ITeamStore} from "data/stores/team/team.store";

export interface IDontLoseTeamModalController extends ViewController {
	readonly i18n: ILocalizationStore;

	get isOpen(): boolean;
	get title(): string;
	get message(): string;

	submit: () => void;
	close: () => void;
}

@injectable()
export class DontLoseTeamModalController implements IDontLoseTeamModalController {
	get isOpen() {
		return this._modalsStore.modal === ModalType.DONT_LOSE_TEAM;
	}

	get title() {
		return this.i18n.t("modal.dont_lose_team.title", "Are you sure you want to leave?");
	}

	private get isCurrentLineupFull() {
		return this._teamStore.isCurrentLineupFull;
	}

	get message() {
		if (this.isCurrentLineupFull) {
			return this.i18n.t(
				"modal.dont_lose_team.description_team_not_full",
				"If you leave now you’re team won’t be saved. Please finish completing your roster"
			);
		}

		return this.i18n.t(
			"modal.dont_lose_team.description",
			"If you leave now you’re team won’t be saved. Make sure you complete your selection to be in with a chance of winning a trip to New York"
		);
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore
	) {
		makeAutoObservable(this);
	}

	submit = () => {
		this._teamStore.setEditTeam(false);
		this._teamStore.setIsTeamChanged(false);
		this._modalsStore.modalContent?.callback?.();
		this._modalsStore.hideModal();
	};

	close = () => {
		this._modalsStore.hideModal();
	};
}
