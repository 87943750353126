import React, {Fragment} from "react";
import {observer} from "mobx-react";
import {Box, Collapse, Stack} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import IconButton from "@mui/material/IconButton";
import styled from "@emotion/styled";
import {TournamentWithPoints} from "views/components/modals/modal_compare/modal_compare.controller";
import {useViewController} from "data/services/locator";
import {Bindings} from "data/constants/bindings";
import {ITournamentController} from "./tournament.controller";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import {Exist} from "views/components/exist/exist.component";
import {Preloader} from "views/components/preloader";
import {LineupRow} from "./lineup_row/lineup_row.component";

const TIcon = styled(Box)`
	width: 48px;
	min-width: 48px;
	height: 48px;
	border-radius: 50%;
	overflow: hidden;
	border: 1px solid rgba(0, 0, 0, 0.25);

	img {
		display: block;
		width: 100%;
	}
`;

const TabsWrapper = styled(Box)`
	border-bottom: 1px solid #949494;

	.MuiTabs-root {
		min-height: 44px;
	}

	.MuiTabs-flexContainer {
		button {
			color: #000000;
			text-align: center;
			font-size: 12px;
			font-weight: 600;
		}
	}

	.MuiTabs-indicator {
		background-color: #000;
	}
`;

const TournamentName = styled.div`
	text-align: left;
	word-break: break-word;
`;

const LineupHead = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 30px) 1fr repeat(3, 30px);
	font-size: 12px;
	font-weight: 600;
	line-height: 135%;
	color: #757575;
	padding: 8px 4px;
	border-bottom: 1px solid #d6d4d2;

	@media (min-width: 768px) {
		grid-template-columns: repeat(3, 41px) 1fr repeat(3, 41px);
		grid-column-gap: 10px;
		padding: 10px 8px;
	}
`;

const BenchTitle = styled.div`
	padding: 8px;
	text-align: center;
	font-size: 12px;
	font-weight: 600;
	line-height: 135%;
	color: #757575;

	@media (min-width: 768px) {
		padding: 10px;
	}
`;

interface ITournament {
	tournament: TournamentWithPoints;
	isOpen: boolean;
	toggleHandler: () => void;
}

export const Tournament: React.FC<ITournament> = observer(({tournament, toggleHandler, isOpen}) => {
	const {
		i18n,
		rounds,
		selectedRoundName,
		changeRoundName,
		isLoading,
		team,
		getValue,
		teamsLineup,
		bench,
		isTournamentDisabled,
	} = useViewController<ITournamentController>(Bindings.TournamentController, {
		isOpen,
		tournamentId: tournament.id,
	});

	return (
		<Fragment>
			<div
				data-tournament-id={tournament.id}
				className="flex items-center justify-between gap-1.5 p-2 font-semibold leading-[145%] text-xs md:text-sm border-b border-solid border-[#D6D4D2]">
				<div className="min-w-9">{tournament.teamAPoints ?? "--"}</div>

				<div className="flex flex-1 items-center max-w-[336px] gap-1 md:gap-2">
					<TIcon>
						<img src={tournament.image} alt="tournament logo" />
					</TIcon>

					<TournamentName>{tournament.name}</TournamentName>

					<IconButton
						onClick={toggleHandler}
						disabled={isTournamentDisabled}
						className={`ml-auto transition-all transform ${isOpen ? "rotate-180" : ""}`}>
						<KeyboardArrowDownIcon />
					</IconButton>
				</div>

				<div className="min-w-9">{tournament.teamBPoints ?? "--"}</div>
			</div>

			<Collapse in={isOpen}>
				<Exist when={isLoading}>
					<Stack p={2} justifyContent={"center"} alignItems={"center"}>
						<Preloader />
					</Stack>
				</Exist>

				<Exist when={!isLoading}>
					<div className="border-b border-solid border-[#D6D4D2]">
						<TabsWrapper>
							<Tabs
								variant="scrollable"
								value={selectedRoundName}
								onChange={changeRoundName}>
								{rounds.map((roundName) => (
									<Tab
										className="whitespace-nowrap flex-1 min-h-[44px] md:min-h-[50px]"
										label={i18n.t("modal.compare.round", "Round {{X}}", {
											X: roundName,
										})}
										value={roundName}
										key={roundName}
									/>
								))}
							</Tabs>
						</TabsWrapper>

						{team && (
							<Fragment>
								<div className="flex justify-between py-2 px-2 font-semibold border-b border-solid border-black leading-[135%] text-xs md:py-2.5">
									<div>{getValue(team.teamA.rank)}</div>
									<div>{i18n.t("modal.compare.rank", "RANK")}</div>
									<div>{getValue(team.teamB.rank)}</div>
								</div>

								<div className="flex justify-between py-2 px-2 font-semibold border-b border-solid border-black leading-[135%] text-xs md:py-2.5">
									<div>{getValue(team.teamA.pts)}</div>
									<div>{i18n.t("modal.compare.round_total", "ROUND TOTAL")}</div>
									<div>{getValue(team.teamB.pts)}</div>
								</div>

								<LineupHead>
									<div>{i18n.t("modal.compare.pos", "POS")}</div>
									<div>{i18n.t("modal.compare.tot", "TOT")}</div>
									<div>
										{i18n.t(
											`modal.compare.r${team.roundName}`,
											`R${team.roundName}`
										)}
									</div>
									<div>{i18n.t("modal.compare.starters", "STARTERS")}</div>
									<div>{i18n.t("modal.compare.pos", "POS")}</div>
									<div>{i18n.t("modal.compare.tot", "TOT")}</div>
									<div>
										{i18n.t(
											`modal.compare.r${team.roundName}`,
											`R${team.roundName}`
										)}
									</div>
								</LineupHead>

								{teamsLineup.map((data, index) => (
									<LineupRow key={index} {...data} getValue={getValue} />
								))}

								<BenchTitle>{i18n.t("modal.compare.bench", "BENCH")}</BenchTitle>

								{bench.map((data, index) => (
									<LineupRow key={index} {...data} getValue={getValue} isBench />
								))}
							</Fragment>
						)}
					</div>
				</Exist>
			</Collapse>
		</Fragment>
	);
});
