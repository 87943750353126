import {action, computed, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IRoundsStore} from "data/stores/rounds/rounds.store";
import type {ILeague, ILeaguesStore, ILeagueUsers} from "data/stores/leagues/leagues.store";
import type {ChangeEvent} from "react";
import type {IUpdateLeaguePayload} from "data/providers/api/leagues.api.provider";
import type {IUserStore} from "data/stores/user/user.store";
import type {IApiResponse} from "data/services/http";
import type {AxiosError} from "axios";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {identity, isEqual, values} from "lodash";
import {LeaguePrivacy, LeagueStatus, ModalType, RequestState, RoundStatus} from "data/enums";
import {extractErrorMessage} from "data/utils";
import {TrackAnalytics} from "data/utils/analytic_service";

interface IProps {
	leagueId: number;
}

interface IForm extends HTMLFormElement {
	leagueName: HTMLInputElement;
	// startId: HTMLInputElement;
	privacy: HTMLInputElement;
}

interface IFormValue {
	leagueName: string;
	tournamentIds: number[];
	privacy: LeaguePrivacy;
}

export interface ILeagueSettingsController extends ViewController<IProps> {
	readonly i18n: ILocalizationStore;

	get userID(): number;
	get league(): ILeague | null;
	get isLoading(): boolean;
	get isLeaguePresenceRequestLoading(): boolean;
	get isLeagueUsersRequestLoading(): boolean;
	get isUpdateButtonDisabled(): boolean;
	get isLeagueNameFieldDisabled(): boolean;
	get isLeagueStarted(): boolean;
	get isFormDisabled(): boolean;
	get isCommissioner(): boolean;
	get formValue(): IFormValue;
	get leagueUsers(): ILeagueUsers | null;

	toggleTournamentsModal: () => void;
	handleUpdateLeague: () => void;
	handleFormChange: (event: ChangeEvent<IForm>) => void;
	leaveLeague: () => void;
	joinLeague: () => void;
	loadMoreUsers: () => void;
	removeUser: (userId: number) => void;
}

@injectable()
export class LeagueSettingsController implements ILeagueSettingsController {
	@observable private _leagueId?: number;
	@observable private _leagueDisposer?: ReturnType<typeof reaction>;
	@observable private _leagueTournamentsDisposer?: ReturnType<typeof reaction>;

	@observable private _requestsStates = {
		general: RequestState.PENDING,
		leaguePresence: RequestState.IDLE,
		leagueUsers: RequestState.IDLE,
		removeUser: RequestState.IDLE,
	};

	@observable private _formValue: IFormValue = {
		leagueName: "",
		tournamentIds: [],
		privacy: LeaguePrivacy.PRIVATE,
	};

	private get leagueID() {
		return this._leagueId!;
	}

	get userID() {
		return this._userStore.user!.id;
	}

	get league() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueById(this._leagueId);
	}

	get leagueUsers() {
		if (!this._leagueId) return null;
		return this._leaguesStore.getLeagueUsersByLeagueId(this._leagueId);
	}

	@computed get rounds() {
		return this._roundsStore.list.filter((round) =>
			isEqual(round.status, RoundStatus.Scheduled)
		);
	}

	get formValue() {
		return this._formValue;
	}

	get isLoading() {
		return isEqual(this._requestsStates.general, RequestState.PENDING);
	}

	get isLeaguePresenceRequestLoading() {
		return isEqual(this._requestsStates.leaguePresence, RequestState.PENDING);
	}

	get isLeagueUsersRequestLoading() {
		return isEqual(this._requestsStates.leagueUsers, RequestState.PENDING);
	}

	get isFormDisabled() {
		return this.isLoading || this.isLeagueStarted;
	}

	get isLeagueNameFieldDisabled() {
		return this.isLoading;
	}

	get isLeagueStarted() {
		return !isEqual(this.league?.status, LeagueStatus.SCHEDULED);
	}

	get isUpdateButtonDisabled() {
		if (this.isLeagueStarted) return !this.isFormValid;
		return this.isFormDisabled || !this.isFormValid;
	}

	get isCommissioner() {
		return isEqual(this.league?.leagueManager?.userId, this._userStore.user!.id);
	}

	@computed private get isFormValid() {
		return values(this._formValue).every(identity);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.RoundsStore) private _roundsStore: IRoundsStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	toggleTournamentsModal = () => {
		this._leaguesStore.toggleTournamentsModal();

		TrackAnalytics.trackOnAction({
			event: "Button Clicked",
			SelectionName: "customize_schedule",
			LeagueName: this.league?.name,
		});
	};

	@action private createSuccessHandlerOf = (type: keyof typeof this._requestsStates) => () => {
		this._requestsStates[type] = RequestState.SUCCESS;
	};

	@action private createErrorHandlerOf =
		(type: keyof typeof this._requestsStates) => (error: AxiosError<IApiResponse>) => {
			this._requestsStates[type] = RequestState.ERROR;
			this._modalsStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(error),
			});
		};

	@action private setPendingState = (type: keyof typeof this._requestsStates) => {
		this._requestsStates[type] = RequestState.PENDING;
	};

	handleUpdateLeague = () => {
		this.setPendingState("general");

		const {leagueName, tournamentIds, privacy} = this._formValue;

		const payload: IUpdateLeaguePayload = {
			tournamentIds,
			name: leagueName,
			leagueId: this.leagueID,
		};

		if (!this.isLeagueStarted) {
			payload.privacy = privacy;
			// payload.startId = startId;
		}

		if (!this.league?.isActive) {
			payload.isActive = true;
		}

		this._leaguesStore
			.updateLeague(payload)
			.then(this.createSuccessHandlerOf("general"))
			.catch(this.createErrorHandlerOf("general"));

		TrackAnalytics.trackOnAction({
			event: "Button Clicked",
			SelectionName: "update_settings",
			LeagueName: this.league?.name,
		});
	};

	@action handleFormChange = (event: ChangeEvent<IForm>) => {
		const {leagueName, privacy} = event.currentTarget;

		this._formValue.leagueName = leagueName.value;
		this._formValue.privacy = privacy.value as LeaguePrivacy;
	};

	@action leaveLeague = () => {
		this._modalsStore.showModal(ModalType.CONFIRM, {
			message: this.i18n.t(
				"league.leave.confirm",
				"Do you really want to leave this league?"
			),
			callback: () => {
				this._modalsStore.hideModal();
				this.setPendingState("leaguePresence");

				this._leaguesStore
					.leaveLeague({leagueId: this.leagueID})
					.then(() => this._leaguesStore.fetchLeagueUsers({leagueId: this.leagueID}))
					.then(this.createSuccessHandlerOf("leaguePresence"))
					.catch(this.createErrorHandlerOf("leaguePresence"));
			},
		});
	};

	@action joinLeague = () => {
		const code = this.league?.code;

		if (!code) return;

		this.setPendingState("leaguePresence");

		this._leaguesStore
			.joinToLeague({code})
			.then(() => this._leaguesStore.fetchLeagueUsers({leagueId: this.leagueID}))
			.then(this.createSuccessHandlerOf("leaguePresence"))
			.catch(this.createErrorHandlerOf("leaguePresence"));
	};

	@action loadMoreUsers = () => {
		this.setPendingState("leagueUsers");

		this._leaguesStore
			.fetchMoreLeagueUsers({leagueId: this.leagueID})
			.then(this.createSuccessHandlerOf("leagueUsers"))
			.catch(this.createErrorHandlerOf("leagueUsers"));
	};

	@action removeUser = (userId: number) => {
		const userName =
			this.leagueUsers?.users.find((user) => user.userId === userId)?.displayName ||
			"this user";

		this._modalsStore.showModal(ModalType.CONFIRM, {
			message: this.i18n.t(
				"league_setting.manage_user.confirm_remove",
				`Do you really want to remove ${userName} from the league?`,
				{
					X: userName,
				}
			),
			callback: () => {
				this._modalsStore.hideModal();
				this.setPendingState("removeUser");

				this._leaguesStore
					.removeUserFromLeague({userId, leagueId: this.leagueID})
					.then(this.createSuccessHandlerOf("removeUser"))
					.catch(this.createErrorHandlerOf("removeUser"));
			},
		});
	};

	private onLoadPageTrack = () => {
		TrackAnalytics.trackOnLoad({
			LeagueName: this.league?.name,
		});
	};

	@action init({leagueId}: IProps) {
		this._leagueId = leagueId;

		this.onLoadPageTrack();

		Promise.all([
			// this._roundsStore.fetchRounds(),
			this._leaguesStore.fetchLeagueUsers({leagueId}),
		])
			.then(this.createSuccessHandlerOf("general"))
			.catch(this.createErrorHandlerOf("general"));

		this._leagueDisposer = reaction(
			() => this.league,
			() => {
				if (!this.league) return;

				const {name, privacy} = this.league;

				this._formValue.tournamentIds = this._leaguesStore.tournamentsIds;
				this._formValue.leagueName = name;
				this._formValue.privacy = privacy;
			},
			{fireImmediately: true}
		);

		this._leagueTournamentsDisposer = reaction(
			() => this._leaguesStore.tournamentsIds,
			(tournamentsIds) => {
				this._formValue.tournamentIds = tournamentsIds;
			}
		);
	}

	dispose() {
		this._leagueDisposer?.();
		this._leagueTournamentsDisposer?.();
	}
}
