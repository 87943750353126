import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeaderboardsStore} from "data/stores/leaderboards/leaderboards.store";
import type {IPlayerModalStore} from "data/stores/player_modal/player_modal.store";
import type {IUserStore} from "data/stores/user/user.store";
import {GigyaScreenType, TournamentStatus} from "data/enums";
import {Cookie} from "data/utils/cookie";
import {isAndroid, isIOS} from "react-device-detect";
import {SSO} from "data/utils/gigya_sso";
import {TrackAnalytics} from "data/utils/analytic_service";
import {ISidebarRanking} from "data/providers/json/json.provider";
import type {ITournament, ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import {take} from "lodash";

export interface ILeaderboardWidgetController extends ViewController {
	readonly i18n: ILocalizationStore;

	get sidebarRanking(): ISidebarRanking[];
	get liveTournamentRanking(): ISidebarRanking[];
	get isAuth(): boolean;
	get selectedTournament(): ITournament | null;
	get isSelectedTournamentLive(): boolean;

	openPlayerModal: (playerId: number) => void;
	onGigyaLogin: () => void;
	onTrackAction: () => void;
}

@injectable()
export class LeaderboardWidgetController implements ILeaderboardWidgetController {
	private _cookies = new Cookie();
	_sso = new SSO();

	get sidebarRanking() {
		return this._leaderboardsStore.sidebarRanking;
	}

	get liveTournamentRanking() {
		const topPlayers = take(this._leaderboardsStore.playersRanking, 10);

		return topPlayers.map((player) => {
			return {
				id: player.id,
				firstName: player.firstName,
				lastName: player.lastName,
				headshot: player.headshot,
				position: Number(player.position),
				pts: player.pts,
				thru: player.thru,
				tot: player.tot,
			};
		}) as ISidebarRanking[];
	}

	get isAuth() {
		return this._userStore.isAuthorized;
	}

	get selectedTournament() {
		return this._tournamentsStore.selectedTournament;
	}

	get isSelectedTournamentLive() {
		return this._tournamentsStore.selectedTournament?.status === TournamentStatus.Playing;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore,
		@inject(Bindings.LeaderboardsStore) public _leaderboardsStore: ILeaderboardsStore,
		@inject(Bindings.PlayerModalStore) public _playerModalStore: IPlayerModalStore,
		@inject(Bindings.TournamentsStore) public _tournamentsStore: ITournamentsStore
	) {
		makeAutoObservable(this);
	}

	openPlayerModal = (playerId: number) => {
		this._playerModalStore.setPlayerId(playerId);
		this._playerModalStore.setPageArea("fantasy_leaderboard_sidebar");
		this._playerModalStore.openPlayerModal();
	};

	handleAppLogin = () => {
		if (isAndroid) {
			window.android?.postMessage(JSON.stringify({action: "promptLoginToUser"}));
		}

		if (isIOS) {
			window.webkit?.messageHandlers.ios.postMessage(
				JSON.stringify({action: "promptLoginToUser"})
			);
		}
	};

	onGigyaLogin = () => {
		this.onTrackAction();

		if (this._cookies.isAppSSO()) {
			this.handleAppLogin();
		} else {
			this._sso.showScreenSet(GigyaScreenType.Login);
		}
	};

	onTrackAction = () => {
		TrackAnalytics.trackOnAction({
			event: "Button Clicked",
			SelectionName: "view_leaderboard",
		});
	};
}
