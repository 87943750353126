import {action, makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {ITutorialStep, TUTORIAL_STEPS} from "data/constants";
import {eq} from "lodash";
import {Bindings} from "data/constants/bindings";
import type {IUserStore} from "data/stores/user/user.store";

export interface ITutorialStore {
	get isOpen(): boolean;
	get activeStep(): number;
	get isLastStep(): boolean;
	get currentStep(): ITutorialStep;
	get tutorialKey(): string;

	toggleOpenState(): void;
	setActiveStep(step: number): void;
}

@injectable()
export class TutorialStore implements ITutorialStore {
	@observable private _isOpen: boolean = false;
	@observable private _activeStep: number = 0;
	@observable private _tutorialSteps: ITutorialStep[] = TUTORIAL_STEPS;
	private _tutorialKey = "tutorial";

	get isOpen() {
		return this._isOpen;
	}

	get activeStep() {
		return this._activeStep;
	}

	get isLastStep() {
		return eq(this._activeStep + 1, TUTORIAL_STEPS.length);
	}

	get currentStep() {
		return this._tutorialSteps[this._activeStep];
	}

	get tutorialKey() {
		return `${this._tutorialKey}_${this._userStore.user?.id || 0}`;
	}

	constructor(@inject(Bindings.UserStore) private _userStore: IUserStore) {
		makeAutoObservable(this);
	}

	@action toggleOpenState() {
		this._isOpen = !this._isOpen;
	}

	@action setActiveStep(step: number) {
		this._activeStep = step;
	}
}
