import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ILeaderboardsStore, IPlayerRanking} from "data/stores/leaderboards/leaderboards.store";
import type {IPlayerModalStore} from "data/stores/player_modal/player_modal.store";

export interface IPlayersLeaderboardController extends ViewController {
	readonly i18n: ILocalizationStore;

	get rankingList(): IPlayerRanking[];

	openPlayerModal: (playerId: number) => void;
}

@injectable()
export class PlayersLeaderboardController implements IPlayersLeaderboardController {
	get rankingList() {
		return this._leaderboardsStore.playersRanking;
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaderboardsStore) public _leaderboardsStore: ILeaderboardsStore,
		@inject(Bindings.PlayerModalStore) public _playerModalStore: IPlayerModalStore
	) {
		makeAutoObservable(this);
	}

	openPlayerModal = (playerId: number) => {
		this._playerModalStore.setPlayerId(playerId);
		this._playerModalStore.setPageArea("fantasy_leaderboard");
		this._playerModalStore.openPlayerModal();
	};
}
