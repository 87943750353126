import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {IJSONProvider} from "data/providers/json/json.provider";

export interface ISegment {
	id: number;
	name: string;
	startDate: string;
	endDate: string;
}

export interface ISegmentsStore {
	get list(): ISegment[];

	getSegmentById(segmentId: number): ISegment | undefined;
	fetchSegments(): Promise<void>;
}

@injectable()
export class SegmentsStore implements ISegmentsStore {
	@observable private _list: ISegment[] = [];

	get list() {
		return this._list;
	}

	constructor(@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider) {
		makeAutoObservable(this);
	}

	getSegmentById(segmentId: number): ISegment | undefined {
		return this._list.find((segment) => segment.id === segmentId);
	}

	@action
	async fetchSegments() {
		const {data} = await this._jsonProvider.segments();

		runInAction(() => {
			this._list = data;
		});
	}
}
