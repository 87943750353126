import {action, makeAutoObservable, observable} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {extractErrorMessage} from "data/utils";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {ModalType, RequestState} from "data/enums";
import type {ILeague, ILeaguesStore} from "data/stores/leagues/leagues.store";
import {find, isEqual} from "lodash";
import {ViewController} from "data/types/structure";
import {TrackAnalytics} from "data/utils/analytic_service";
import {useNavigate} from "react-router-dom";

const buttonClicked = "Button Clicked";

interface IParams {
	navigate?: ReturnType<typeof useNavigate>;
}

export interface ILeaguesSearchController extends ViewController<IParams> {
	readonly i18n: ILocalizationStore;
	get isLoading(): boolean;
	get hasRegenerateLeagues(): boolean;
	get searchValue(): string;
	get leaguesForJoinNextPage(): boolean;
	get leaguesForJoin(): ILeague[];

	joinLeague: (id: number) => void;
	isJoinInProgress: (id: number) => boolean;
	onSearchLeagueForJoinChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onSearchMyLeague: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onTrackCreateLeague: () => void;
	onTrackJoinLeague: () => void;
	onTrackRegenerateLeagues: () => void;
	loadMoreLeaguesForJoin: () => void;
}

@injectable()
export class LeaguesSearchController implements ILeaguesSearchController {
	@observable searchTimer: ReturnType<typeof setTimeout> | undefined;
	@observable private _searchValue: string = "";
	@observable _requestState = RequestState.IDLE;
	@observable _joiningLeagueId: number | undefined = undefined;
	@observable _navigate?: ReturnType<typeof useNavigate>;

	get isLoading() {
		return isEqual(this._requestState, RequestState.PENDING);
	}

	get searchValue() {
		return this._searchValue;
	}

	get hasRegenerateLeagues() {
		return this._leaguesStore.hasRegenerateLeagues;
	}

	get leaguesForJoinNextPage() {
		return this._leaguesStore.leaguesForJoin.nextPage;
	}

	get leaguesForJoin() {
		return this._leaguesStore.leaguesForJoin.leagues;
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	init({navigate}: IParams) {
		this._navigate = navigate;
	}

	@action private onError = (error: AxiosError<IApiResponse>) => {
		this._requestState = RequestState.ERROR;
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action private onSuccess = () => {
		this._requestState = RequestState.SUCCESS;
	};

	@action public onSearchLeagueForJoinChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		this._leaguesStore.clearLeaguesForJoin();
		const {name, value} = event.currentTarget;

		if (!name) {
			return;
		}
		this._searchValue = value;
		this._requestState = RequestState.PENDING;

		clearTimeout(this.searchTimer);
		this.searchTimer = setTimeout(() => {
			this._leaguesStore
				.fetchLeaguesForJoin({
					search: this._searchValue,
				})
				.then(this.onSuccess)
				.catch(this.onError);
		}, 500);
	};

	@action public loadMoreLeaguesForJoin = () => {
		this._requestState = RequestState.PENDING;

		this._leaguesStore
			.fetchMoreLeaguesForJoin({
				search: this._searchValue,
			})
			.then(this.onSuccess)
			.catch(this.onError);
	};

	@action public onSearchMyLeague = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const {name, value} = event.currentTarget;

		if (!name) {
			return;
		}
		if (value === "") {
			this._leaguesStore.clearLeaguesForJoin();
			this._searchValue = "";
			return;
		}
		this._searchValue = value;
		this._requestState = RequestState.PENDING;

		clearTimeout(this.searchTimer);
		this.searchTimer = setTimeout(() => {
			this._leaguesStore
				.fetchMyLeagues({
					search: this._searchValue,
				})
				.then(this.onSuccess)
				.catch(this.onError);
		}, 500);
	};

	private redirectToMyLeagueByCode = (code: string) => {
		const {leagues} = this._leaguesStore.myLeagues;
		const id = find(leagues, {code})?.id;
		if (id) this._navigate?.(`/league/${id}/`, {replace: false});
	};

	@action public joinLeague = (id: number) => {
		const code = find(this.leaguesForJoin, {id})?.code;

		if (!code) return;

		this._requestState = RequestState.PENDING;
		this._joiningLeagueId = id;

		this._leaguesStore
			.joinToLeague({code})
			.then(() => this.redirectToMyLeagueByCode(code))
			.catch(this.onError);
	};

	isJoinInProgress = (id: number) => {
		return this._joiningLeagueId === id;
	};

	public onTrackCreateLeague = () => {
		TrackAnalytics.trackOnAction({
			event: buttonClicked,
			SelectionName: "create_league",
		});
	};

	public onTrackJoinLeague = () => {
		TrackAnalytics.trackOnAction({
			event: buttonClicked,
			SelectionName: "join_league",
		});
	};

	public onTrackRegenerateLeagues = () => {
		TrackAnalytics.trackOnAction({
			event: buttonClicked,
			SelectionName: "regenerate_leagues",
		});
	};
}
