import {action, makeAutoObservable, observable, reaction} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {
	IPlayerModalStore,
	IPlayerModalResponse,
} from "data/stores/player_modal/player_modal.store";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import type {IPlayer, IPlayersStore} from "data/stores/players/players.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {PlayerModalTabValue, PlayerStatus} from "data/enums";
import {SyntheticEvent} from "react";
import type {IPoolPlayersStore} from "data/stores/pool_players/pool_players.store";
import {eq, get, isNumber, uniq} from "lodash";
import {IPoolPlayer} from "data/providers/api/tournament.provider";
import {TrackAnalytics} from "data/utils/analytic_service";

export interface IPlayerModalController extends ViewController {
	readonly i18n: ILocalizationStore;

	get isLoading(): boolean;
	get isOpen(): boolean;
	get currentPlayer(): IPlayer | undefined;
	get playerData(): IPlayerModalResponse | null;
	get selectedTab(): PlayerModalTabValue;
	get currentRoundIndex(): number;
	get poolPlayer(): IPoolPlayer | undefined;
	get isActivePlayer(): boolean;
	get playerCourseAbbr(): string;

	close: () => void;
	onTabChange: (_event: SyntheticEvent, value: PlayerModalTabValue) => void;
}

@injectable()
export class PlayerModalController implements IPlayerModalController {
	@observable private _selectedTab: PlayerModalTabValue = PlayerModalTabValue.SCORECARD;
	private _disposerReaction!: ReturnType<typeof reaction>;

	get isLoading() {
		return this._playerModalStore.isLoading;
	}

	get isOpen() {
		return this._playerModalStore.isOpen;
	}

	get currentPlayer() {
		return this._playerStore.getPlayerById(this._playerModalStore.playerId);
	}

	get poolPlayer() {
		return this._poolPlayerStore.getPoolPlayerById(this._playerModalStore.playerId);
	}

	get isActivePlayer() {
		return eq(this.poolPlayer?.status, PlayerStatus.Active);
	}

	get playerData() {
		return this._playerModalStore.playerData;
	}

	get selectedTab() {
		return this._selectedTab;
	}

	get currentRoundIndex() {
		return this._tournamentStore.currentRoundIndex;
	}

	get playerCourseAbbr() {
		const playerCoursesIds = uniq(
			this.poolPlayer?.roundStats
				.map(({courseId}) => courseId)
				.filter((courseId) => isNumber(courseId))
		) as number[];

		if (playerCoursesIds.length <= 1) {
			return "";
		}

		const courseId = this.poolPlayer?.roundStats.find(
			({roundId}) => roundId === this._tournamentStore.selectedRound?.id
		)?.courseId;

		return (
			this._tournamentStore.selectedTournament?.courses.find(({id}) => id === courseId)
				?.abbreviation || ""
		);
	}

	constructor(
		@inject(Bindings.LocalizationStore) public readonly i18n: ILocalizationStore,
		@inject(Bindings.PlayerModalStore) public _playerModalStore: IPlayerModalStore,
		@inject(Bindings.TournamentsStore) public _tournamentStore: ITournamentsStore,
		@inject(Bindings.PlayersStore) public _playerStore: IPlayersStore,
		@inject(Bindings.PoolPlayersStore) public _poolPlayerStore: IPoolPlayersStore
	) {
		makeAutoObservable(this);
	}

	close = () => {
		this._playerModalStore.closePlayerModal();
	};

	@action onTabChange = (_event: SyntheticEvent, value: PlayerModalTabValue) => {
		this._selectedTab = value;
		this.onTrackAction();
	};

	dispose() {
		this._disposerReaction?.();
	}

	init() {
		this._disposerReaction = reaction(
			() => this._playerModalStore.isOpen,
			() => {
				if (this._playerModalStore.playerId && this._playerModalStore.isOpen) {
					this.onTrackAction();
					void this._playerModalStore.fetchPlayerModal({
						tournamentId: this._tournamentStore.selectedTournamentId,
						playerId: this._playerModalStore.playerId,
					});
				}
			},
			{fireImmediately: false}
		);
	}

	onTrackAction = () => {
		TrackAnalytics.trackOnAction({
			event: "Modal Viewed",
			PageArea: this._playerModalStore.pageArea,
			Segment: this.selectedTab,
			PlayerId: [get(this.currentPlayer, "feedId", "")],
		});
	};
}
