import "reflect-metadata";

import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {breadcrumbsIntegration, browserTracingIntegration, init} from "@sentry/react";
import React, {Suspense} from "react";
import reportWebVitals from "./reportWebVitals";
import {RootRoutes, SecretGateRoutes} from "routes";
import {
	createBrowserRouter,
	createRoutesFromElements,
	Outlet,
	Route,
	RouterProvider,
} from "react-router-dom";
import {createRoot} from "react-dom/client";
import {
	controllers,
	DIContainer,
	providers,
	services,
	stores,
} from "data/services/locator/locator_container.service";
import {InjectionProvider} from "data/services/locator/locator_provider.service";
import {StyledEngineProvider} from "@mui/material/styles";
import {ThemeProvider} from "@mui/material";
import {BASE_URL, SENTRY_DSN_URL} from "data/constants";
import {PagePreloader} from "views/components/preloader";
import {SecretGateController} from "views/controllers/secret_gate/secret_gate.controller";
import {ModalError} from "views/components/modals/modal_error/modal_error.component";
import {ModalConfirm} from "views/components/modals/modal_confirm/modal_confirm.component";
import {theme} from "assets/theming/theme";
import {Bootstrap} from "views/components/bootstrap/bootstrap.component";
import {Session} from "views/components/session/session.component";
import {PlayerModal} from "views/components/player_modal/player_modal.component";
import {ModalCompare} from "views/components/modals/modal_compare/modal_compare.component";
import {TutorialModal} from "views/components/modals/modal_tutorial/modal_tutorial.component";

const IGNORE_ERRORS = [
	"Authentication Required",
	"Load failed",
	"UET is not defined",
	"Loading chunk",
	"AbortError: Share canceled",
	"Failed to fetch",
	"Minified Redux error",
	"Unexpected end of script",
	"Abort due to cancellation of share",
	"The network connection was lost.",
	"Maximum call stack size exceeded",
	"Possible side-effect in debug-evaluate",
	"Native bridge was not set",
	"Initialization Error: Native bridge was not set",
	"UnhandledRejection: Non-Error promise rejection captured with keys: message, status",
	"Non-Error promise rejection captured with keys: message, status",
	"TypeError: undefined is not an object (evaluating 'ceCurrentVideo.currentTime')",
	"Identifier MlbJavascriptDataStore has already been declared",
	"Identifier 'MlbJavascriptDataStore' has already been declared",
	"SyntaxError: Identifier MlbJavascriptDataStore has already been declared",
	"SyntaxError: Identifier 'MlbJavascriptDataStore' has already been declared",
	"Java exception was raised during method invocation",
	"ReferenceError: isSelected is not defined",
	/Network Error/i,
	/Fetch Error/i,
	"Can't find variable: _AutofillCallbackHandler", //Instagram browser issue
	/^Non-Error promise rejection captured with value: .*$/,
];

if (SENTRY_DSN_URL) {
	init({
		dsn: SENTRY_DSN_URL,
		integrations: [
			browserTracingIntegration(),
			breadcrumbsIntegration({
				console: false,
			}),
		],
		environment: process.env.REACT_APP_SENTRY_ENV || "development",
		allowUrls: [
			".loc",
			".fanhubmedia.com",
			".geniussports.com",
			"www.mlb.com",
			".mlb.com",
			"fantasygolf.pgatour.com",
		],
		ignoreErrors: IGNORE_ERRORS,
		denyUrls: [
			"quantcast",
			"xsca",
			// browser's extensions
			/extensions\//i,
			/^chrome:\/\//i,
			/^moz-extension:\/\//i,
		],
		sampleRate: 0.1,
	});
}

const root = document.getElementById("root");

if (!root) {
	throw Error("Root element was not found");
}

DIContainer.load(services, providers, stores, controllers);

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route
			element={
				<Suspense fallback={<PagePreloader />}>
					<Outlet />
				</Suspense>
			}>
			{SecretGateController.IS_SECRET_PASSED ? RootRoutes : SecretGateRoutes}
		</Route>
	),
	{
		basename: BASE_URL,
	}
);

createRoot(root).render(
	<React.StrictMode>
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<InjectionProvider container={DIContainer}>
					<Bootstrap>
						<Session>
							<RouterProvider router={router} />
						</Session>

						<ModalError />
						<ModalConfirm />
						<PlayerModal />
						<ModalCompare />
						<TutorialModal />
					</Bootstrap>
				</InjectionProvider>
			</ThemeProvider>
		</StyledEngineProvider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
