import {action, makeAutoObservable, observable, runInAction} from "mobx";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILeaderboardsProvider} from "data/providers/api/leaderboards.provider";
import {IPaginationParams} from "data/types/main";
import type {IPoolPlayer, ITournamentPlayersPayload} from "data/providers/api/tournament.provider";
import type {IJSONProvider, ISidebarRanking} from "data/providers/json/json.provider";
import {cloneDeep} from "lodash";
import {OrderBy, SortOrder} from "data/enums";

interface ILeagueLeaderboardParams extends IPaginationParams {
	leagueId: number;
	seasonSegmentId?: number;
	tournamentId?: number;
	orderBy?: string | null;
	orderDirection?: string | null;
}

export interface ILeaderboards {
	rankings: ILeaderboardItem[];
	user: ILeaderboardItem | null;
	nextPage: boolean;
}

export interface ILeaderboardItem {
	userId: number;
	teamName: string | null;
	displayName: string;
	rank: number | null;
	prevRank: number | null;
	overallPoints: number | null;
	seasonSegmentPoints: number | null;
	tournamentPoints: number | null;
}

export interface IPlayerRanking extends IPoolPlayer {
	position: string | null;
	prevPosition: string | null;
}

const defaultLeaderboard = {
	rankings: [],
	user: null,
	nextPage: false,
};

interface IOrderFilter {
	orderBy: string;
	orderDirection: SortOrder;
}

const DEFAULT_ORDER_FILTER: IOrderFilter = {
	orderBy: OrderBy.TotalPoints,
	orderDirection: SortOrder.ASC,
};

export interface ILadderFilter {
	search: string;
	selectedSegmentId: number;
	selectedTournamentId: number;
}

const DEFAULT_LADDER_FILTER: ILadderFilter = {
	search: "",
	selectedSegmentId: 0,
	selectedTournamentId: 0,
};

export interface ILeaderboardsStore {
	get leaderboard(): ILeaderboards;
	get orderFilter(): IOrderFilter;
	get ladderFilter(): ILadderFilter;
	get playersRanking(): IPlayerRanking[];
	get sidebarRanking(): ISidebarRanking[];

	fetchLeaderboard: (params: IPaginationParams) => Promise<void>;
	fetchLeaderboardMore: (params: IPaginationParams) => Promise<void>;
	fetchWeekLeaderboard: (weekId: number, params: IPaginationParams) => Promise<void>;
	fetchWeekLeaderboardMore: (weekId: number, params: IPaginationParams) => Promise<void>;
	fetchLeagueLeaderboard: (params: ILeagueLeaderboardParams) => Promise<void>;
	fetchLeagueLeaderboardMore: (params: ILeagueLeaderboardParams) => Promise<void>;

	fetchPlayersRanking(payload: ITournamentPlayersPayload): Promise<void>;
	fetchSidebarRanking(): Promise<void>;
	clearStore(): void;
	setOrderFilter: (filter: IOrderFilter) => void;
	setSelectedSegmentId: (value: number) => void;
	setSelectedTournamentId: (value: number) => void;
	setSearchValue: (value: string) => void;
}

@injectable()
export class LeaderboardsStore implements ILeaderboardsStore {
	@observable _leaderboard: ILeaderboards = cloneDeep(defaultLeaderboard);
	@observable _playersRanking: IPlayerRanking[] = [];
	@observable _sidebarRanking: ISidebarRanking[] = [];
	@observable _orderFilter: IOrderFilter = DEFAULT_ORDER_FILTER;
	@observable _ladderFilter: ILadderFilter = DEFAULT_LADDER_FILTER;

	constructor(
		@inject(Bindings.LeaderboardsProvider) private _leaderboardsProvider: ILeaderboardsProvider,
		@inject(Bindings.JSONProvider) private _jsonProvider: IJSONProvider
	) {
		makeAutoObservable(this);
	}

	get leaderboard(): ILeaderboards {
		return this._leaderboard;
	}

	get orderFilter() {
		return this._orderFilter;
	}

	get ladderFilter() {
		return this._ladderFilter;
	}

	get playersRanking(): IPlayerRanking[] {
		return this._playersRanking;
	}

	get sidebarRanking() {
		return this._sidebarRanking;
	}

	@action setSelectedSegmentId = (value: number) => {
		this._ladderFilter.selectedSegmentId = value;
	};

	@action setSelectedTournamentId = (value: number) => {
		this._ladderFilter.selectedTournamentId = value;
	};

	@action setSearchValue = (value: string) => {
		this._ladderFilter.search = value;
	};

	@action setOrderFilter = (filter: IOrderFilter) => {
		this._orderFilter = filter;
	};

	@action async fetchPlayersRanking(payload: ITournamentPlayersPayload) {
		try {
			const {data} = await this._jsonProvider.tournamentPlayerLeaderboard(payload);

			runInAction(() => {
				this._playersRanking = data;
			});
		} catch (_err) {
			console.log(_err);
		}
	}

	@action async fetchSidebarRanking() {
		try {
			const {data} = await this._jsonProvider.sidebarRanking();

			runInAction(() => {
				this._sidebarRanking = data;
			});
		} catch (_err) {
			console.log(_err);
		}
	}

	fetchLeaderboard = async (params: IPaginationParams) => {
		const result = await this._leaderboardsProvider.overall(params);

		runInAction(() => {
			this._leaderboard = result.data.success;
		});
	};

	fetchLeaderboardMore = async (params: IPaginationParams) => {
		const result = await this._leaderboardsProvider.overall(params);
		runInAction(() => {
			this._leaderboard = {
				...result.data.success,
				rankings: [...this._leaderboard.rankings, ...result.data.success.rankings],
			};
		});
	};

	fetchWeekLeaderboard = async (weekId: number, params: IPaginationParams) => {
		const result = await this._leaderboardsProvider.week({
			gameWeek: weekId,
			...params,
		});
		runInAction(() => {
			this._leaderboard = result.data.success;
		});
	};

	fetchLeagueLeaderboard = async (params: ILeagueLeaderboardParams) => {
		const result = await this._leaderboardsProvider.league(params);

		runInAction(() => {
			this._leaderboard = result.data.success;
		});
	};

	fetchLeagueLeaderboardMore = async (params: ILeagueLeaderboardParams) => {
		const result = await this._leaderboardsProvider.league(params);

		runInAction(() => {
			this._leaderboard = {
				...result.data.success,
				rankings: [...this._leaderboard.rankings, ...result.data.success.rankings],
			};
		});
	};

	fetchWeekLeaderboardMore = async (weekId: number, params: IPaginationParams) => {
		const result = await this._leaderboardsProvider.week({
			gameWeek: weekId,
			...params,
		});
		runInAction(() => {
			this._leaderboard = {
				...result.data.success,
				rankings: [...this._leaderboard.rankings, ...result.data.success.rankings],
			};
		});
	};

	clearStore = () => {
		this._leaderboard = cloneDeep(defaultLeaderboard);
		this._ladderFilter = DEFAULT_LADDER_FILTER;
	};
}
