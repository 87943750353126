import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILeaguesStore} from "data/stores/leagues/leagues.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {ISegment, ISegmentsStore} from "data/stores/segments/segments.store";
import {isEmpty} from "lodash";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";

export interface ILeagueTournamentsModalController extends ViewController {
	readonly i18n: ILocalizationStore;

	get isOpenTournamentsModal(): boolean;
	get segments(): ISegment[];
	get isSaveDisabled(): boolean;

	toggleTournamentsModal: () => void;
	cancel: () => void;
}

@injectable()
export class LeagueTournamentsModalController implements ILeagueTournamentsModalController {
	get isOpenTournamentsModal() {
		return this._leaguesStore.isOpenTournamentsModal;
	}

	get segments() {
		return this._segmentsStore.list;
	}

	get isSaveDisabled() {
		return isEmpty(this._leaguesStore.tournamentsIds);
	}

	constructor(
		@inject(Bindings.LocalizationStore) readonly i18n: ILocalizationStore,
		@inject(Bindings.LeaguesStore) private _leaguesStore: ILeaguesStore,
		@inject(Bindings.SegmentsStore) private _segmentsStore: ISegmentsStore,
		@inject(Bindings.TournamentsStore) private _tournamentsStore: ITournamentsStore
	) {
		makeAutoObservable(this);
	}

	toggleTournamentsModal = () => {
		this._leaguesStore.toggleTournamentsModal();
	};

	cancel = () => {
		this._leaguesStore.addTournamentArrToList(this._tournamentsStore.scheduleTournamentsIds);
	};
}
