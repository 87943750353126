import keyAddIcon from "assets/img/keys/key_add.svg";
import keyEditIcon from "assets/img/keys/key_edit.svg";
import keyLockedIcon from "assets/img/keys/key_locked.svg";
import keyRemoveIcon from "assets/img/keys/key_remove.svg";
import keyCaptainIcon from "assets/img/keys/key_captain.svg";
import keyAutoSwapIcon from "assets/img/keys/key_auto_swap.svg";
import keyUsesIcon from "assets/img/keys/key_uses.svg";
import keySwapIcon from "assets/img/keys/key_swap.svg";
import tutorialImage0 from "assets/img/tutorial/0.png";
import tutorialImage1 from "assets/img/tutorial/1.svg";
import tutorialImage2 from "assets/img/tutorial/2.svg";
import tutorialImage3 from "assets/img/tutorial/3.svg";
import tutorialImage4 from "assets/img/tutorial/4.svg";
import tutorialImage5 from "assets/img/tutorial/5.svg";

export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const CONTENT_JSON_URL = process.env.REACT_APP_CONTENT_JSON_URL || "";
export const BASE_URL = process.env.REACT_APP_BASE_URL || "/";
export const SENTRY_DSN_URL = process.env.REACT_APP_SENTRY_DSN_URL;
export const SHARE_URL = process.env.REACT_APP_SHARE_URL || "";
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";
export const SITE_URL = process.env.REACT_APP_SITE_URL || "";
export const ORIGIN_FRAME_URL = window.location.href;

export const EMAIL_REGEXP = "\\S+@\\S+\\.\\S+";
export const PASSWORD_PATTERN =
	"^((?=.*?\\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[\\-!@#$%^&*\\(\\)_+\\|~=`\\{\\}\\[\\]:\";'<>?,.\\/])).{8,}$";

export const PASSWORD_REQUIREMENTS =
	"Password must include 8 characters, including 1 upper case character, 1 number and 1 special character";

export const FACEBOOK_ID = process.env.REACT_APP_FACEBOOK_APP_ID || false;

export const GAME_SLUG = process.env.REACT_APP_GAME_SLUG || "";
export const GIGYA_KEY = process.env.REACT_APP_GIGYA_KEY || "";
export const GIGYA_SCREENSET_ID =
	process.env.REACT_APP_GIGYA_SCREENSET_ID || "Fantasy-RegistrationLogin";
export const IS_SKIP_SSO = JSON.parse(process.env.REACT_APP_IS_SKIP_SSO || "false") as boolean;

interface IKeys {
	name: string;
	icon: string;
}

export const KEYS_DATA: IKeys[] = [
	{
		name: "add",
		icon: keyAddIcon,
	},
	{
		name: "edit",
		icon: keyEditIcon,
	},
	{
		name: "locked",
		icon: keyLockedIcon,
	},
	{
		name: "remove",
		icon: keyRemoveIcon,
	},
	{
		name: "captain",
		icon: keyCaptainIcon,
	},
	{
		name: "auto_swap",
		icon: keyAutoSwapIcon,
	},
	{
		name: "uses",
		icon: keyUsesIcon,
	},
	{
		name: "swap",
		icon: keySwapIcon,
	},
];

export interface ITutorialStep {
	title: string;
	description: string;
	image?: string;
	isLastStep?: boolean;
	isFirstStep?: boolean;
}

export const TUTORIAL_STEPS: ITutorialStep[] = [
	{
		title: "tutorial.step0.title",
		description: "tutorial.step0.message",
		image: tutorialImage0,
		isFirstStep: true,
	},
	{
		title: "tutorial.step1.title",
		description: "tutorial.step1.message",
		image: tutorialImage1,
	},
	{
		title: "tutorial.step2.title",
		description: "tutorial.step2.message",
		image: tutorialImage2,
	},
	{
		title: "tutorial.step3.title",
		description: "tutorial.step3.message",
		image: tutorialImage3,
	},
	{
		title: "tutorial.step4.title",
		description: "tutorial.step4.message",
		image: tutorialImage4,
	},
	{
		title: "tutorial.step5.title",
		description: "tutorial.step5.message",
		image: tutorialImage5,
	},
	{
		title: "tutorial.step6.title",
		description: "tutorial.step6.message",
		image: tutorialImage0,
		isLastStep: true,
	},
];
