import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {action, makeAutoObservable, observable, runInAction} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {ModalType, RequestState, SaveButtonState} from "data/enums";
import {extractErrorMessage} from "data/utils";
import {isEqual, noop} from "lodash";
import type {IAPILogin, IAPIRegister, IGigyaResponse} from "data/providers/api/user.api.provider";
import {ApiError} from "data/utils/api_errors";
import type {ITeamStore} from "data/stores/team/team.store";
import type {ITournamentsStore} from "data/stores/tournaments/tournaments.store";
import {Cookie} from "data/utils/cookie";
import {IS_SKIP_SSO} from "data/constants";
import {isAndroid, isIOS} from "react-device-detect";
import {TrackAnalytics} from "data/utils/analytic_service";

export interface ISessionController extends ViewController {
	get isSessionChecked(): boolean;
}

@injectable()
export class SessionController implements ISessionController {
	@observable _isSessionChecked = false;
	@observable private _logoutRequestState = RequestState.IDLE;
	@observable private gigyaResponse?: IGigyaResponse;
	private _cookies = new Cookie();

	get isSessionChecked(): boolean {
		return this._isSessionChecked;
	}

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) private _i18nStore: ILocalizationStore,
		@inject(Bindings.ModalsStore) private _modalsStore: IModalsStore,
		@inject(Bindings.TeamStore) private _teamStore: ITeamStore,
		@inject(Bindings.TournamentsStore) private _tournamentsStore: ITournamentsStore
	) {
		makeAutoObservable(this);
	}

	@action onError = (error: AxiosError<IApiResponse>) => {
		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action private markSessionAsChecked = () => {
		this._isSessionChecked = true;
	};

	@action private handleLoginError = (error: AxiosError<IApiResponse>) => {
		if (isEqual(error.response?.status, ApiError.USER_NOT_REGISTERED)) {
			this._modalsStore.showModal(ModalType.SECONDARY_REGISTRATION, {
				message: "",
			});

			return;
		}

		this._modalsStore.showModal(ModalType.ERROR, {
			message: extractErrorMessage(error),
		});
	};

	@action private APILogin = (params: IAPILogin) => {
		return this._userStore.ssoWebLogin(params).catch(this.handleLoginError);
	};

	@action private APIRegister = (params: IAPIRegister) => {
		return this._userStore.ssoWebRegister({...params}).catch(noop);
	};

	private saveLocalTeam = () => {
		const hasUnsavedTeam = Boolean(JSON.parse(localStorage.getItem("save_team") || "null"));
		const selectedRoundId = this._tournamentsStore.selectedRound?.id;

		if (hasUnsavedTeam && this._userStore.isAuthorized && selectedRoundId) {
			void this._teamStore
				.saveTeam({
					tournamentId: this._tournamentsStore.selectedTournamentId,
					roundId: selectedRoundId,
					...this._teamStore.saveTeamPayload,
				})
				.then(() => localStorage.removeItem("save_team"))
				.catch((error: AxiosError<IApiResponse>) => {
					this._modalsStore.showModal(ModalType.ERROR, {
						message: extractErrorMessage(error),
					});

					this._teamStore.setSaveState(SaveButtonState.SAVE);
				});
		}
	};

	@action private onSSOLogin = () => {
		void this.getSSOUser()
			.then(this.APILogin)
			.then(() => {
				TrackAnalytics.trackOnAction({
					event: "Logged In",
				});

				TrackAnalytics.trackOnLoad();
			})
			.then(() => this.saveLocalTeam());
	};

	private initPGAAppSSO() {
		console.log("initPGAAppSSO", this._cookies.pgaAppPayload());
		this._cookies.setUserId();
		this._userStore
			.ssoAppLogin(this._cookies.pgaAppPayload())
			.then(() => this.saveLocalTeam())
			.catch(this.handleLoginError)
			.finally(this.markSessionAsChecked);
	}

	private initPGAWebSSO() {
		this.onGigyaLogin();

		window.gigya?.accounts.addEventHandlers({
			onLogin: this.onSSOLogin,
			onLogout: this.onGigyaLogout,
		});
	}

	public initSSO() {
		if (this._cookies.isAppSSO()) {
			window.addEventListener("app-login", () => void this.initPGAAppSSO);

			if (!this._cookies.isNeedAuthToApp()) {
				this.initPGAAppSSO();
			} else {
				this.markSessionAsChecked();
			}
		} else {
			this.initPGAWebSSO();
		}
	}

	private handleAppLogin = () => {
		if (isAndroid) {
			window.android?.postMessage({action: "promptLoginToUser"});
		}

		if (isIOS) {
			window.webkit?.messageHandlers.ios.postMessage({action: "promptLoginToUser"});
		}
	};

	private onGigyaLogin() {
		this.getSSOUser()
			.then(this.APILogin)
			.catch(noop) // We don't won't to do anything if SSO doesn't return a user.
			.finally(this.markSessionAsChecked);
	}

	@action private onGigyaLogout = () => {
		this._logoutRequestState = RequestState.PENDING;

		this._userStore
			.ssoLogout()
			.then(this._userStore.clearUser)
			.catch(this.onError)
			.finally(() =>
				runInAction(() => {
					this._logoutRequestState = RequestState.SUCCESS;
				})
			);
	};

	@action private getSSOUser = () => {
		return new Promise<IAPILogin>((resolve, reject) => {
			window.gigya?.accounts.getAccountInfo({
				include: "id_token",
				callback: (resp) => {
					if (resp.errorCode) {
						runInAction(() => {
							reject(resp);
						});

						// void this._userStore.requestUser().then(this.markSessionAsChecked);
						return;
					}

					runInAction(() => {
						const loginParam = {
							uid: resp.UID,
							uidSignature: resp.UIDSignature,
							signatureTimestamp: resp.signatureTimestamp,
						};

						window.amplitude?.setUserId(resp.UID);

						this._userStore.setUserParams(loginParam);
						this._userStore.setUserProfile(resp.profile);

						this.gigyaResponse = {
							...resp.profile,
							...loginParam,
						};

						resolve({...loginParam});
					});
				},
			});
		});
	};

	@action
	async init() {
		// Need for test login/register flow via pga parent App
		// this._cookies.setCookie(
		// 	"pga_session",
		// 	JSON.stringify({gigyaUID: "e77e592532bd4353887f4af3c94879ff", device: "iOS"})
		// );
		// this._cookies.setCookie(
		// 	"pga_hash",
		// 	// "ea15489b6f63bd87379b5e3ebf351fc36184b3dadc0e46a4d14162f330e1285b"
		// 	"68422ec8a87d5a7fd141e4be390a73ac7c32674e3567c6c642075ff0003c40910000000"
		// );
		//
		// console.log(this._cookies.getCookie("pga_session"));
		// console.log(this._cookies.getCookie("pga_hash"));

		if (IS_SKIP_SSO) {
			await this._userStore
				.requestUser()
				.catch(() => {
					// Do nothing as the error is expected when a user isn't authorized
				})
				.then(() =>
					runInAction(() => {
						this._isSessionChecked = true;
					})
				);
		} else {
			this.initSSO();
		}
	}
}
